import React, {useState, useEffect} from 'react';
import qs from "qs";
import ModalInfo from '../generales/ModalInformativo'
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {IconButton, LinearProgress, Box, List, Card, Typography, Grid} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import moment from "moment";
import {llamadaApiCarga, ErrorGeneral} from '../funciones/ClienteAxios';
import { obtenDispo } from '../funciones/IdDispositivo';
const useStyles = makeStyles((theme) => ({

    alturaLista: {
        minHeight: 100, overflow: 'auto',
    }, estiloCards: {
        marginBottom: '.3rem',
        width: '95%'
    }, tamanioFecha: {
        fontSize: 12
    }, tamanioLog: {
        marginLeft: '.4em', fontSize: 12, marginTop: 5
    },
    stat: {
        position: "absolute", top: '55%', transform: 'translateY(-55%)'
    }
}));


export default function ModalBitacora({seleccionado, setModalAbierto, modalAbierto, titulo, subtitulo}) {
    const classes = useStyles();
    const [historial, setHistorial] = useState([]);
    const [espera, setEspera] = useState([]);
    const auxDispV = obtenDispo()
    const usuID = localStorage.getItem('UsuId'); 
    useEffect(() => {              
        
        llenaBitacora()

    }, [])

    const llenaBitacora = () => {
        
        const data = qs.stringify({
            usuario: usuID, solicitud: seleccionado.Id, idDispositivo: auxDispV,
        });
        const url = "solicitud/detalle";
        function respuestaBitacora(auxiliar) {
            if (auxiliar[0].Id !== -1) {
                setHistorial(auxiliar);
            }
        }
        llamadaApiCarga(data, url, respuestaBitacora, ErrorGeneral, setEspera);
    };    

    function generarStatus(color, valor) {
        return (<Card className={classes.tamanioCard} style={{backgroundColor: color, alignContent: 'center'}}>
            <div style={{marginLeft: 2}} className={classes.stat}>
                {valor}</div>
        </Card>)
    }

    const generaDet=(auxDetalle,auxColor)=>{
        return(
        <span>        
            <Card style={{ backgroundColor: auxColor, color: '#FFF', paddingLeft: 8,
                paddingRight: 8, marginTop: 8, marginBottom: 8 }}
            >
                <span>{auxDetalle}</span>
            </Card>              
        </span>
        )
    }


    function generarBitacora() {
        let arrayElementos = historial.map((bitacora) => {
        //console.log('BITACORA', bitacora)
        return (
        <Card className={classes.estiloCards} key={bitacora.Id}
            style={ bitacora.StatusId !== 0 ?
                { borderLeft: 'solid', borderColor: bitacora.StatusColor}
                : { borderLeft: 'solid', borderColor: bitacora.TipoColor } }
        >
            <Grid container alignItems={'center'}>
               
                <Grid item style={{marginLeft: '1rem'}}>
                    <span>
                    <b className={classes.tamanioFecha}>
                        {moment.utc(bitacora.Fecha).format('YYYY-MM-DD HH:mm:ss') === "1900-01-01 00:00" ? 
                            "" : moment.utc(bitacora.Fecha).format('DD/MM/YYYY HH:mm:ss')}
                    </b>
                    </span>
                </Grid>
                <Grid item xs={3} style={{textAlign:'center', marginLeft:'1rem'}}>
                {   bitacora.StatusId !== 0?
                    generaDet(bitacora.StatusNom,bitacora.StatusColor)                    
                :  (bitacora.TipoId !== 0 ?
                        generaDet(bitacora.TipoNom,bitacora.TipoColor)                        
                        :   <Card style={ { backgroundColor: 'tomato', color: '#FFF', paddingLeft: 8, paddingRight: 8,
                                marginTop: 8,marginBottom: 8 } }>
                                <span>Sin cambios</span>
                            </Card>
                    )
                }
                </Grid>
                <Grid item style={{marginLeft: '1rem'}}>
                    <span>{bitacora.UserNom}</span>
                </Grid>
                <Grid item style={{marginLeft: '1rem'}}>
                    <span>{bitacora.Descr}</span>
                </Grid>
            </Grid>
        </Card>)
        });
        return arrayElementos;
    }

    const mostrarBitacora = historial.length > 0 ? generarBitacora() :
        <p style={{textAlign: 'center'}}>Sin resultados</p>;
    const listado = espera ? (<Box><LinearProgress/> </Box>) : (mostrarBitacora)
    const auxTitu=` ${moment.utc(seleccionado.Fnac).format('DD/MM/YYYY')} ${seleccionado.Nombre}`
    return (
    <ModalInfo setModalAbierto={setModalAbierto}
        modalAbierto={modalAbierto} titulo={auxTitu}
        tamanio='md' subtitulo={`${seleccionado.MunNom} Secc ${seleccionado.SeccNum}` }
    >
        <Grid>
            <Typography variant='body2'>{seleccionado.Mensaje}</Typography>
            <Typography variant='body2'>{`${seleccionado.Dir}, ${seleccionado.Tel}`}</Typography>
        </Grid>
        <List className={classes.alturaLista}>
            {listado}
        </List>
    </ModalInfo>)
}