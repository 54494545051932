import {makeStyles} from "@material-ui/core/styles";
import Modal from "../generales/ModalCerrarActualiza";
import {useEffect, useState} from "react";
import {Box , Button, Grid, InputLabel, LinearProgress, MenuItem, Select, TextField, Typography} from "@material-ui/core";
import qs from "qs";
import {ErrorGeneral, llamaApiCargaToken, llamaApiFormDat3,llamadaApiToken} from "../funciones/ClienteAxios";
import {toast} from "react-toastify";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Reporte from './Reporte'
import moment from 'moment';
import { obtenDispo } from '../funciones/IdDispositivo';
import Progress from "../generales/Progress";
import TablaListCarta from "./TablaListCarta";
const useStyles = makeStyles((theme) => ({}));


const ModalRespuestaPDF = ({abrirModal, setAbrirModal, registro, actualiza}) => {
    let history = useHistory();    
    const classes = useStyles();
    const source = axios.CancelToken.source();
    const fechDia=moment(new Date()).format("YYYY-MM-DD");
    const usuId =localStorage.getItem('UsuId') ; 
    const usuNom =localStorage.getItem('UsuarioNom') ; 
    const auxEdo=process.env.REACT_APP_EDO
    const auxDispV =obtenDispo() 
    const [nuevoRegistro, setNuevoRegistro] = useState({        
        Direccion: '', contenido:"",Nombre:"", tel:"", solicitud:""
    });
    const { Direccion,  contenido, Nombre, tel, solicitud} = nuevoRegistro
    const [btnDeshabilitado, setBotonDeshabilitado] = useState(true)
    const [aviso, setAviso] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [cargando, setCargando] = useState(false);
    const [cargaData, setCargaData] = useState(false);
    const [data, setData] = useState([]);
    const [archivo,setArchivo]=useState(null);
    const [progresoEnvio, setProgresoEnvio] = useState(0);
    const [nomIniciales, setNomIniciales] = useState("");
    const [firma, setFirma] = useState("");
    const [listCarta, setListCarta] = useState([]);
    useEffect(() => {
        setNuevoRegistro({...nuevoRegistro,
            Nombre:registro.Nombre, tel:registro.Tel, solicitud:registro.Mensaje})
        obtnInicial()
        getFirma()
        getListCarta()
    }, [])

    useEffect(() => {
       if(archivo)
       {
        setBotonDeshabilitado(false)
       }
      
    }, [archivo])
    
    function getFirma()  {            
        const url= `solicitud/v2/carta/firma`;                            
        const data = qs.stringify({                
            usuario:usuId,                                                                
            idDispositivo:auxDispV,
        });
                    
        function respuesta (aux) 
        {   if (aux) 
            {  
                setFirma(aux.firma)                      
            }  
            else
            {   setFirma("")
            }                                                                    
        }
        llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    function getListCarta()  {            
        const url= `solicitud/v2/carta/list`;                            
        const data = qs.stringify({                
            usuario:usuId,       
            llave:registro.Id,                                                         
            idDispositivo:auxDispV,
        });
                    
        function respuesta (aux) 
        {   if (aux && aux[0].Id!=="0"&& aux[0].Id!==0 && aux[0].Id!=="-1" && aux[0].Id!== -1) 
            {  
                setListCarta(aux)                      
            }  
            else
            {   setListCarta([])
            }                                                                    
        }
        llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    const obtnInicial=()=>{

        let palabras = registro.Nombre
        let array = palabras.split(" ")
        let total = array.length
        let resultado = "";
        for (let index = 0; index < total; index++) {
            const letra=array[index][0]
            if (array[index].length!==0 && letra!=="" && letra!==" " && letra!=='' && letra !==' ') {
                resultado = resultado  +letra                
            }  
        }         
        
        setNomIniciales(resultado)
    }

    const handleClose = () => {
        setAbrirModal(false)
        setAviso(false);
    }

    const onChange = e => {
        setNuevoRegistro({...nuevoRegistro,[e.target.name]: e.target.value})
    }

    const onClickActualiza=()=>{
        if (validaVariable(contenido,"Contenido",10) ) 
        {   
            setData([])
            setCargaData(true)
            setTimeout(() => {
                setData({   "Id": "0",
                    "Folio": "SA/240311/32",
                    "Capturista": usuNom,
                    "Fch": fechDia,
                    "Dest": registro.Nombre,
                    "Firma": firma,
                    "Cuerpo":contenido.trim(),
                    "Tel": registro.Tel
                })    
                setCargaData(false)
            }, 1000);    
        }  
        
        
    }


    const guardarCartaPDF = () => {
        const formData = new FormData();
        setCargando(true)
        setBotonDeshabilitado(true)
        formData.append('llave', registro.Id)
        formData.append('usuario', usuId)   
        formData.append('telefono', registro.Tel)   
        formData.append('emisor', "Att. E.V.")  
        formData.append('destinatario', registro.Nombre) 
        formData.append('contenido', contenido)            
        formData.append('idDispositivo', auxDispV)
        
        formData.append('multi-files', archivo)
        

        const url = 'solicitud/v2/carta/new/file';

        function respuesta(response) {
            if (response.respuesta === 1) {
                setProgresoEnvio(0)
                setAviso(true)
                setCargando(true)
                setBotonDeshabilitado(true)
                toast.success('Carta de evidencia agregada correctamente', {
                    pauseOnFocusLoss: false,
                    toastId: `nvo-evidencia${1}`
                })
                window.open(response.url)
                setAbrirModal(false)
                actualiza()
            } else {
                setBotonDeshabilitado(false);
                setCargando(false)
                toast.error('Datos incorrectos, intente mas tarde', {
                    pauseOnFocusLoss: false,
                    toastId: `error-recorrido${1}`
                })
                setAbrirModal(false)
            }
        }
        const headers={size:archivo.size, tipo: 'SPACE', 
            estado:auxEdo, clase:"SOLICITUD", llave:registro.Id }
        llamaApiFormDat3(formData, url, respuesta, ErrorGeneral, setCargando, history,source, 
            headers,setProgresoEnvio)                   
    }
 
    const verificaNulos = (campo,descripcion)=>{
        let respuesta =false;        
          
        if( (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;                             
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }
    const mandaAlerta=(mensaje,tipoAdvert)=>
        {
            setMensaje(mensaje);
            setTipoAdvertencia(tipoAdvert)  
            setAviso(true);      
        }
    function validaVariable(auxCampo,auxMensaje,auxTamanio) 
    {   let respuesta=false
        if ( !verificaNulos(auxCampo,auxMensaje) ) 
        {   if (auxCampo.length>=auxTamanio ) 
            {                
                respuesta=true                    
            }else
            {            
                mandaAlerta(`Campo ${auxMensaje} incompleto, debe escribir un Contenido \nminimo de 10 caracteres.`,"warning") 
            }
        }        
        return respuesta;
    }

    function guardarDatos(e) {
        e.preventDefault()
        if (validaVariable(contenido,"Contenido",10) && !verificaNulos(archivo,"Contenido") ) 
        {   
            setAviso(false);
            guardarCartaPDF()
           
        }  
    }
    const auxTitu=` ${moment.utc(registro.Fnac).format('DD/MM/YYYY')} ${registro.Nombre}`
    return (
    <Modal
        titulo={auxTitu} modalAbierto={abrirModal} mensaje={mensaje}
        setModalAbierto={setAbrirModal} guardarDatos={guardarDatos}
        tipoAdvertencia={tipoAdvertencia} aviso={aviso} tamanio={'xl'}
        btnDeshabilitado={btnDeshabilitado} setAviso={setAviso}
        cargando={cargando} cerrarBorrar={handleClose}
        subtitulo={`${registro.MunNom} Secc ${registro.SeccNum} ${tel}` }
    >
        <Box display="flex" flexDirection={"row"}>
        <Box  alignItems={'center'} spacing={1} style={{width:"28rem", marginRight:"2rem"}}>
            <Box    style={{marginBottom:'.5rem',marginTop:.5+'rem' }}>
                <TextField id="txt-Solicitud" disabled={true} value={solicitud}
                    label={"Solicitud"} variant="outlined"         size="small"     
                    maxRows={5} minRows={4} style={{width:"28rem"}} multiline                         
                />
            
            </Box>
         {/*    <Box display={"flex"} flexDirection={"row"} >
             
                <Typography   style={{marginBottom:.5+'rem',marginTop:.5+'rem', width:"8rem"}}>{tel}</Typography>
                <Typography  color="primary" style={{marginBottom:.5+'rem',marginTop:.5+'rem', width:"28rem",fontWeight:"700"}}>{Nombre}</Typography>
            </Box> */}
            {  listCarta.length!==0 ? 
                <Box display={"flex"} flexDirection={"row"} >     
                    <Typography  color="primary" style={{marginBottom:.5+'rem' , width:"28rem",fontWeight:"700"}}>
                        {`Listado de cartas (${listCarta.length})`}
                    </Typography>
                </Box>
            :null  }
            {  listCarta.length!==0 ?  
                <Box   >
                <TablaListCarta listSuge={listCarta} listExcel={[]} />
            </Box>
            :null  }
            <Box  >
                <TextField style={{marginTop:".5rem"}}
                    id="txt-contenido" name="contenido"
                    label="Contenido" variant="outlined"
                    multiline fullWidth onChange={onChange}
                    minRows={4} value={contenido} maxRows={6}
                />
            </Box>
            <Typography  style={{ width:"28rem",fontSize:"13px"}}>
                NOTA: La carta se genera con la información que  capturo antes de presionar el botón VISTA PREVIA, si realiza un cambio presione nuevamente el botón VISTA PREVIA</Typography>
            <Button variant="outlined" onClick={onClickActualiza} style={{marginTop:"1rem"}} color="primary">
                Vista Previa
            </Button>
            {progresoEnvio > 0 ? (
                    <Progress progress={progresoEnvio}/>)
            : null}
        </Box>
        <Box>
            { data.length!==0 ?
           <Box  style={{ height:"28rem",  }} >
               
                <Reporte urlReport={"./reports/ReportCarta240503.mrt"} informacion={data} nombre={`${nomIniciales}`} mostrarTB={false} 
                    setArchivo={setArchivo}
                />               
            </Box>
            
            : (!cargaData? null :<Box width={"20rem"} style={{marginTop:"1rem"}}><LinearProgress/></Box>) }
        </Box>
        </Box>
    </Modal>);
};

export default ModalRespuestaPDF;
