import React,{useState,useEffect} from 'react';
import {Box, LinearProgress,Select,FormControl,InputLabel,
    MenuItem, TextField
} from "@material-ui/core";
import TablaAsistencia from './TablaAsistencia';
import axios from "axios";
import qs from "qs";
import moment from 'moment';
import { useHistory } from "react-router-dom";
import IntervaloSelect from './IntervaloTabla';
import IconBuscar from '../generales/Iconos/IconBuscar';
import {llamadaApiToken,llamaApiCargaToken,ErrorGeneral } from '../funciones/ClienteAxios';
import {GeneralesContext} from '../context/GeneralesContext'
import SelectList from '../generales/SelectList';
import { obtenDispo } from '../funciones/IdDispositivo';
import {findNumberCad} from '../funciones/FindNumber'
const Asistencia = () => {
    let history = useHistory();         
    const auxDispV =obtenDispo()      
    const source = axios.CancelToken.source();
    //let token =localStorage.getItem('token20') ;  
    const usuId =localStorage.getItem('UsuId') ;     
    const cadena = localStorage.getItem("Operacion")+"|"; 
    const {generales,guardarGenerales}=React.useContext(GeneralesContext);                    
    const [listSuge, setListSuge] = useState([])
    const[listExcel, setListExcel] = useState([])  
    const [fSelect, setFSelect] = useState({inicio:new Date(),final:new Date()});    
    const [filtro, setFiltro] = useState({Nombre:"", Municipio:0, Seccion:"",tipoDet:"", Descr:""})
    const [espera, setEspera] = useState(false)
    const [actualizar, setActualizar] = useState(false)
    const {Nombre, Municipio, Seccion,tipoDet, Descr}= filtro

    useEffect(() => {                
                
        if (usuId !== null && usuId !== undefined  ) 
        {
            guardarGenerales({...generales,Titulo:'Asistencia'})            
        }
        else
        {   
            localStorage.clear();                            
            history.push("/");  
        }
        
        return ()=> {
            source.cancel();
        }  
    }, [])

    useEffect(() => {                        
        llenaListSug()
    }, [actualizar])

    const llenaListSug = () => {
        const fInicio=moment(fSelect.inicio).format("YYYY-MM-DD");
        const fFinal=moment(fSelect.final).format("YYYY-MM-DD");
        const data = qs.stringify({
            usuario:usuId,
            fini:fInicio+"T00:00:00",
            ffin:fFinal+"T23:59:59",
            idDispositivo: auxDispV,
        });
        const url = "asistencia/filtro";
        function respuestaListSug(auxiliar) {
            if (auxiliar[0].Id !== -1) {
                if (findNumberCad('|36|',cadena) ) {
                    const  arrM=[["#","Fecha Registro", "Folio", "Estatus", "Tipo", "Detalle"]]              
                    auxiliar.forEach((row,index) => {
                        const auxFReg =
                            moment.utc(row.Fecha).format("YYYY-MM-DD HH:mm") === "1900-01-01 00:00"
                            ? ""
                            : moment.utc(row.Fecha).format("DD/MM/YYYY HH:mm");                                           
                        arrM.push([index+1,auxFReg, row.Folio, row.StatusNom, ])
                    });         
                    setListExcel(arrM)    
                }
                
                setListSuge(auxiliar);      
            }else{
                setListSuge([]);      
            }
        }
    
        llamaApiCargaToken(data, url, respuestaListSug,ErrorGeneral,setEspera,history,source);
    };


    
    const btnBuscar=(e)=>{
        e.preventDefault()
       // llenaListSug()
       setActualizar(!actualizar)
    }           

    return (
    <div style={{marginLeft:.5+'rem'}} >
        <Box display={'flex'} mt={2} mb={1}>
            <Box >
                <IconBuscar leyenda="Buscar" handleClick={(e)=>btnBuscar(e)} />                                                                                                                        
            </Box>    
            <Box>
                <IntervaloSelect fSelect={fSelect}  setFSelect={setFSelect} />
            </Box>                                         
        </Box>
        { !espera  ? <TablaAsistencia listSuge={listSuge}  listExcel={listExcel} />
            :    <Box pt={3}><LinearProgress/> </Box>
        }
        
    </div>
    );
};

export default Asistencia;