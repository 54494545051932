import React, { useState } from "react";
import {  //Box,LinearProgress,
  IconButton, Table, TableBody,
  TableCell, TableContainer, TableHead,
  TableRow, Card, Tooltip,
  TablePagination,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import XLSX from 'xlsx';
import ModalBitacora from "./BitacoraSug";
import ModalDetalles from "./ModalDetalles";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import EditIcon from "@material-ui/icons/Edit";
import ModalSugerencia from "./ModalSugerencia";
import PaginacionTab from "../layout/PaginacionTab";
import {numeroPag} from "../funciones/Paginacion";
import {findNumberCad} from '../funciones/FindNumber'
import SaveIcon from '@material-ui/icons/Save';
import ModalRespuestaPDF from "./ModalRespPDF";
import MailIcon from '@material-ui/icons/Mail';
const useStyles = makeStyles({
  
  container: {
    //maxHeight: 550,
    maxHeight: window.innerHeight - 250,
    minHeight: 280,
    width: "91 rem",
  },
  container2: {
    maxHeight: 650,
    //maxHeight: window.innerHeight-170,
    //minHeight: 280,
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: process.env.REACT_APP_Color_Second,
    },
  },
  tableCell: {
    "$selected &": {
      color: "white",
    },
  },
  hover: {},
  selected: {},
});

const columnas = [
  { id: 1, titulo: "Fecha Registro", alinear: "center", },
  { id: 15, titulo: "Folio", alinear: "center", },
  { id: 2, titulo: "Estatus", alinear: "center",  },
  { id: 3, titulo: "Tipo", alinear: "center",  },
  { id: 12, titulo: "Detalle",alinear: "center",  },
  { id: 4, titulo: "Municipio",alinear: "center",  },
  { id: 5, titulo: "Sección",alinear: "center",  },
  { id: 13, titulo: "Registro",alinear: "center",  },
  { id: 14, titulo: "Asignado ",alinear: "center",  },
  { id: 6, titulo: "Nacimiento", alinear: "center", },    
  //{id: 9, titulo: "Dominio",alinear: "center",   },      
  { id: 7, titulo: "Genero", alinear: "center",  },
  { id: 8, titulo: "Teléfono", alinear: "center",  },  
  { id: 9, titulo: "Nombre", alinear: "center",  },  
  { id: 10, titulo: "Solicitud", alinear: "center",  },
  { id: 11, titulo: "Email", alinear: "center",  },
];

const TablaSugePublico = ({  listSuge, listTipo, listStatus, actualizar, setActualizar, listExcel,setListSuge}) => {
  const classes = useStyles();
  let history = useHistory();
  const usuId =localStorage.getItem('UsuId') ? parseInt(localStorage.getItem('UsuId')):0;     
  const source = axios.CancelToken.source();
  const cadena = localStorage.getItem("Operacion")+"|"; 
  const fondo =process.env.REACT_APP_Fondo_Color
  const tamMax = 100
  const [datos, guardarDatos] = useState([]);
  const [sinDatos, guardarSinDatos] = useState(true);
  const [espera, setEspera] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const [regisSelect, setRegisSelect] = useState([]);
  const [tituloMapa, setTituloMapa] = useState("");
  const [modalBitacora, setModalBitacora] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalPDF, setModalPdf] = useState(false);
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [numPagina, setNumPagina] = useState(0);
  
  React.useEffect(() => {
    
    if (listSuge.length!==0 &&listSuge[0].Id!=="0" &&listSuge[0].Id!==0 ) {
      numeroPag(listSuge, tamMax, setTotalPaginas, setNumPagina, guardarDatos)
      //guardarDatos(listSuge);  
    }else{
      guardarDatos([]);
    }
    
    //console.log(listSuge);
    return () => {
      source.cancel();
    };
  }, [listSuge]);

  const seleccionado = (auxId, auxOrden) => {
    setSelectedID(auxId);
    setRegisSelect(auxOrden);
  };

  function tipoEdoProspecto(auxColorStd, etiqueta) {
    const renglon = (
      <TableCell align="center" padding={"none"}
        style={{ color: "white", fontWeight: 700, width: 80 + "px",
          paddingLeft: 0.4 + "rem", paddingRight: 0.4 + "rem",}}
      >
        <Card
          style={{ color: auxColorStd === "#EBF1F8" ? "black" : "white",
            fontWeight: "bold", backgroundColor: auxColorStd,
          }}
        >
          {etiqueta}
        </Card>
      </TableCell>
    );
    return renglon;
  }

  function abrirModalInfo(auxSelec) {
    setModalInfo(true);
    const auxFch=moment.utc(auxSelec.Fecha).format('DD/MM/YYYY HH:mm')
    let titulo = `Registro ${auxFch} ${auxSelec.Id} `;
     //+ '\n' + seleccionado.MunNom
    setTituloMapa(titulo);
    setRegisSelect(auxSelec);
  }

  function abrirModalBitacora(auxSelec) {
    setModalBitacora(true);
    //let titulo=`Registro ${auxSelec.Id} `;
    //setTituloMapa(titulo)
    setRegisSelect(auxSelec);
  }

  function abrirModalPDF(auxSelec) {
    setModalPdf(true);  
    setRegisSelect(auxSelec);
  }

  function abrirModalEditar(auxSelec) {
    setModalEdit(true);
    setRegisSelect(auxSelec);
  }

  const celda = (alinear, estilo, contenido) => {
    return (
    <TableCell align={alinear} className={classes.tableCell}
      padding={"none"} style={estilo}
    >
      {contenido}
    </TableCell>
    );
  };

  const celdaIco = (leyenda, funcionModal, childrenIco, registro) => {
    const auxCont = (
      <Tooltip title={leyenda}>
        <IconButton aria-label="add circle" size="small"
          onClick={() => funcionModal(registro)} component="span"
        >
          {childrenIco}
        </IconButton>
      </Tooltip>
    );
    return celda("center", { width: 10 + "px" }, auxCont);
  };

  function tipoEdoProspecto(auxColorStd, etiqueta) {
    const renglon = (
      <TableCell align="center" padding={"none"}
        style={{ color: "white", fontWeight: 700, width: 100 + "px",
          paddingLeft: 0.4 + "rem", paddingRight: 0.4 + "rem",
        }}
      >
        <Card
          style={{ color: auxColorStd === "#EBF1F8" ? "black" : "white",
            fontWeight: "bold", backgroundColor: auxColorStd,
          }}
        >
          {etiqueta}
        </Card>
      </TableCell>
    );
    return renglon;
  }

  const obtenerGen=(aux)=>{
    let respuesta=""
    switch (aux) {
      case 'H':
        respuesta="Hombre"
        break;
      case 'M':
        respuesta="Mujer"
        break;
      case 'X':
        respuesta="No Binario"
        break;
      case 'N':
        respuesta="Pendiente"
        break;
      default:
        break;
    }
    return respuesta
  }

  function primerMayus( val ) {
  
    return val.toLowerCase()
              .trim()
              .split(' ')
              .map( v => v[0].toUpperCase() + v.substr(1) )
              .join(' ');  
  }

  function Renglon(props) {
    const { row } = props;
    const auxMuni = row.MunNom.charAt(0) + row.MunNom.slice(1).toLowerCase();
    const auxFReg =
      moment.utc(row.Fecha).format("YYYY-MM-DD HH:mm") === "1900-01-01 00:00"
        ? ""
        : moment.utc(row.Fecha).format("DD/MM/YYYY HH:mm");
    const auxFNac =
      moment.utc(row.Fnac).format("YYYY-MM-DD HH:mm") === "1900-01-01 00:00"
      ? ""
      : moment.utc(row.Fnac).format("DD/MM/YYYY");
    const btnBitacora =
      selectedID === row.Id ? (
        <AccessTimeIcon style={{ color: "white", width: 18 + "px" }} />
      ) : (
        <AccessTimeIcon style={{ width: 18 + "px" }} color="primary" />
      );
    const leyendaBitacora = `Bitácora de registro`;
 
    const btnEditar =
      selectedID === row.Id ? (
        <EditIcon style={{ color: "white", width: 18 + "px" }} />
      ) : (
        <EditIcon style={{ width: 18 + "px" }} color="primary" />
      );

      const btnPdf =
      selectedID === row.Id ? (
        <MailIcon style={{ color: "white", width: 18 + "px" }} />
      ) : (
        <MailIcon style={{ width: 18 + "px" }} color="primary" />
      );
    const leyendaPdf = `Carta Pdf`;
    const leyendaEditar = `Editar Estatus/Tipo`;
    return (    
    <TableRow
      onClick={() => { seleccionado(row.Id, row) }}
      className={classes.tableRow} selected={selectedID === row.Id}
      classes={{ hover: classes.hover, selected: classes.selected }}
    >
      {usuId===1? celdaIco(leyendaPdf, abrirModalPDF, btnPdf, row):null}
      {celdaIco(leyendaEditar, abrirModalEditar, btnEditar, row)}
 
      {celdaIco(leyendaBitacora, abrirModalBitacora, btnBitacora, row)}
      {celda("center", { width: "110px", fontSize: "10px", paddingRight: "2px" }, auxFReg )}
      {celda("left", { width: "80px", fontSize: "11px", paddingRight: "2px", fontWeight:700}, row.Folio )}
      {tipoEdoProspecto(row.StatusColor, row.StatusNom)}
      {tipoEdoProspecto(row.TipoColor, row.TipoNom)}
      {celda("left", { width: "80px", fontSize: "11px", paddingRight: "2px" }, row.TipoDtNom )}
      {celda("left", { width: "160px", fontSize: "12px", paddingRight: "2px" }, auxMuni )}
      {celda("center", { width: "60px", fontSize: "12px", paddingRight: "2px" }, row.SeccNum )}      
      {celda("center", { width: "80px", fontSize: "12px", paddingRight: "2px" }, row.UserReg )}  
      {celda("center", { width: "80px", fontSize: "12px", paddingRight: "2px" }, row.Asignado )}      
      {celda("center", { width: "80px", fontSize: "10px", paddingRight: "2px" }, auxFNac )}
      {celda("center", { fontSize: "12px", width:"80px"}, obtenerGen(row.Genero))}      
      {celda("center",{ fontSize: "10px", paddingLeft: "8px",width:"40px" }, row.Tel)}               
      
     
      {celda( "left", { width: 240, fontSize: "12px", paddingLeft: "8px" }, row.Nombre)}
      {celda("left",{ width: "240px", fontSize: "12px", paddingLeft: "8px" }, row.Mensaje)}
      {celda("left", { fontSize: "12px", paddingLeft: "8px" }, row.Email)}        
      
    </TableRow>  
    );
  }

  const vacia = (
    <TableCell className={classes.tableCell} align="center">
      {" "}
    </TableCell>
  );
  const ColumnaVacia = (
    <TableCell
      className={classes.tableCell}
      align="center"
      style={{ backgroundColor: fondo, color: "white" }}
    >
      {" "}
    </TableCell>
  );

  const exportarArch = () => {
    let today = new Date();                    
    let fec = moment(today);        
    //console.log(listaExcel);
    const ws = XLSX.utils.aoa_to_sheet(listExcel);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Reporte");
    XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+" Lista Solicitudes.xlsx")
  }; 

  function tablaCompleta(auxlista) {
    return (
    <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {usuId ===1?ColumnaVacia:null}
            {findNumberCad('|36|',cadena)?
            <TableCell align="center" padding={'none'} style={{ backgroundColor:fondo,color:'white'}}  >     
              <IconButton onClick={exportarArch} size="small" >
                <Tooltip title="Exportar a Excel">
                  <SaveIcon style={{color:'white'}} size="small" />
                </Tooltip>
              </IconButton>                                  
            </TableCell>:ColumnaVacia }              
            <TableCell
              align="center"
              padding={"none"}
              style={{ backgroundColor: fondo, color: "white" }}
            >
              {auxlista.length}
            </TableCell>
            {columnas.map((row) => {
              return (
                <TableCell
                  style={{ backgroundColor: fondo, color: "white" }}
                  key={row.titulo + row.id}
                  align={row.alinear}
                  padding={"none"}
                >
                  {row.titulo}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {auxlista.length != 0
            ? auxlista.map((row) => (
                <Renglon key={"regPerfilGen" + row.Id} row={row} />
              ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
    );
  }

  function onChangePagina(event, newPage) {
    event.preventDefault();
    if (newPage === 0) {
        guardarDatos(listSuge.slice(0, tamMax))
    } else {
      guardarDatos(listSuge.slice(newPage * tamMax, newPage * tamMax + tamMax))
    }
    setNumPagina(newPage)
  }

  function generarFooter() {
    return (
    <TablePagination style={{padding:0}}
      rowsPerPageOptions={false} colSpan={3}
      rowsPerPage={tamMax} count={listSuge.length}
      page={numPagina} lang='es'
      labelDisplayedRows={
        ({from, to, count}) => {
          return `${from}-${to} de ${count}`
        }
      }
      onPageChange={onChangePagina}
      ActionsComponent={PaginacionTab}/>)
  }

  const tablaSinDatos = () => {
    return (
    <TableContainer className={classes.container2}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>              
            {columnas.map((row) => {
              return (
                <TableCell
                  style={{ backgroundColor:fondo, color: "white" }}
                  key={row.titulo + row.id}
                  align={row.alinear}
                  padding={"none"}
                >
                  {row.titulo}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.tableRow}>
            {vacia}
            {vacia}              
            <TableCell className={classes.tableCell} align="center">
              Sin{" "}
            </TableCell>
            {vacia}
            {vacia}
            <TableCell className={classes.tableCell} align="center">
              Registros
            </TableCell>
            {vacia}
            {vacia}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    );
  };

  const contenido = datos.length !== 0 ? tablaCompleta(datos) : tablaSinDatos();
  //const tabla = espera ?  (<Box pt={3}><LinearProgress/> </Box>) :( contenido )
  const modEdit = modalEdit ? (
    <ModalSugerencia
      seleccionado={regisSelect} listSuge={listSuge} datos={datos}
      modalAbierto={modalEdit} setListSuge={setListSuge}
      setModalAbierto={setModalEdit}
      titulo={regisSelect.Nombre}
      listStatus={listStatus}
      listTipo={listTipo}
      setActualizar={setActualizar}
      actualizar={actualizar}
    />
  ) : null;

  const modBitacora = modalBitacora ? (
    <ModalBitacora
      seleccionado={regisSelect}
      modalAbierto={modalBitacora}
      setModalAbierto={setModalBitacora}
      titulo={regisSelect.Nombre}
    />
  ) : null;

  const modInfo = modalInfo ? (
    <ModalDetalles
      seleccionado={regisSelect}
      modalAbierto={modalInfo}
      setModalAbierto={setModalInfo}
      titulo={regisSelect.Nombre}
    />
  ) : null;

  const modRespPdf = modalPDF && regisSelect.length!==0?<ModalRespuestaPDF abrirModal={modalPDF} setAbrirModal={setModalPdf} registro={regisSelect} actualiza={()=>{}} />:null

  const paginacion = !espera && datos.length !== 0 && totalPaginas > 1 ? generarFooter() : null;
  return (
  <div style={{ minWidth: 80 + "rem", maxWidth: 91 + "rem" }}>
    {paginacion}
    {modRespPdf}
    {contenido}
    {modBitacora}
    {modInfo /*  */}
    {modEdit}
  </div>
  );
};

export default TablaSugePublico;
