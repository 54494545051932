import React,{useEffect,useState} from 'react';
import {HorizontalBar,Bar} from 'react-chartjs-2';
import {Card,CardContent,Box, Typography} from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  contenedorCard:{
    //paddingRight:'3rem'
    height:30+'rem',marginRight:".3rem"
  },
  card:{
    boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
    //color:'#4a4a4a',
  },
  tituloGraf:{
    paddingTop:'0rem',
    textAlign:'center'
  },
  nested: {
    paddingLeft: theme.spacing(2),
  },
}));

export default function GraficasAvSecc({valor,listTipos}){
  const classes = useStyles();
  const color1= '#19A11E';  //ff1744  
  const color2= '#FBBE06';
  const arreColores=["#038545", "#EF8012", "#347AF4", "#FFC107", "#119A4F", "#B406D8", "#FFA318", "#DC4A3D", "#32CA81", 
    "#F9B546", "#ED0808", "#0097A7", "#880E4F", "#4BAC7D", "#C52363", "#F57F06", "#7C594C", "#DBE772"]
  const auxColor=process.env.REACT_APP_Fondo_Color
  const [data,setData]= useState([]);
  const [leyenda,setLeyenda]= useState([]);
  const [bandera,setBandera]= useState(false);

  useEffect(()=>{    
          
    let nvoArrTipo=[], nvoArrData=[]
    listTipos.map((tipo)=>{
      const auxVal=valor[`T${tipo.Id}`]
      nvoArrTipo.push(`${tipo.Nom} - ${auxVal}`) 
      nvoArrData.push(auxVal)
    })
    setLeyenda(nvoArrTipo)
    setData(nvoArrData)
    setBandera(true)
  },[valor])

  const dataGenero = {
    labels: [''],
    datasets: [
           
      { label:'Lona',
        type: 'bar',
        data: [data.TotLona], //[50, 40, 30, 50],
        backgroundColor:'#4BAC7D',
        borderColor: '#4BAC7D',
        //fill: false,
        tension: 0.4           
      },      
    ],
  }; 

  const dataRecor = {
    labels: leyenda,
    datasets: [{
      data: data,
      backgroundColor: arreColores,
      hoverBackgroundColor: [
        color1,
        color2
      ]
    }]
  }; 

  const options = {
  
  //  indexAxis:'y',
  plugins: {
    legend: {
        display: false
    }
  },
    tooltips: {
      enabled: true,
    },
    indexAxis:'y',
    title:{
      fontStyle: 'bold',
    },
    scales: {
      x: {
          display: true,
          title: {
          display: true
          }
      },
      y: {
          display: true,
          title: {
          display: false,
       
          },
       
      },
      }
  } 

  function  llenadoTabla2(datos)
  {
    return(
    <Card className={classes.card} style={{paddingRight:.8+'rem',paddingLeft:.8+'rem' 
      ,paddingBottom:.5+'rem',height:30+'rem',width:35+'rem'}} >              
      <Bar
        id="graficaMuni"
        data={datos}
        width={650} height={530} 
        options={options}
      />   
    </Card>
    );
  }

  return (
  <Box display="flex" flexDirection="row" justifyContent={'center'} mb={1} style={{marginTop:1+'rem'}} >           
     <Box display="flex"className={classes.contenedorCard}  >
        {bandera ?llenadoTabla2(dataRecor):null}
      </Box>      
    {/*   <Box display="flex"className={classes.contenedorCard}  >
        {bandera ?llenadoTabla2(dataEdad,process.env.REACT_APP_Promov):null}
      </Box>  */}    
  </Box>
  );
}