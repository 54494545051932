import axios from "axios";
import qs from "qs";
import {ErrorGeneral, llamadaApiToken} from "../funciones/ClienteAxios";
import { obtenDispo } from '../funciones/IdDispositivo';
export const EnviarPerfil=(perfil, toast,source,history)=>{
    const idusu = localStorage.getItem("UsuId")?parseInt(localStorage.getItem("UsuId")):0;    
    const auxDispV =obtenDispo()  
    const getSocialSend = () => {               
        const data = qs.stringify({
            Llave:perfil.Id,
            usuario:idusu  ,   
            idDispositivo:auxDispV,
        });   
        const url = "perfil/v1/social/send";
        function respuesta(aux) {
            if (aux.length !== 0&& aux.respuesta===1) 
            {  
                const auxMsn=aux.Mensaje.replace("\n", " ")
                toast.success(auxMsn, {
                    pauseOnFocusLoss: false,
                    toastId: `send-perfil${perfil.Id}`
                }) 
            }
            else{
                toast.warning("Ha ocurrido un error, intentalo mas tarde", {
                    pauseOnFocusLoss: false,
                    toastId: `send-perfil${perfil.Id}`
                }) 
            }       
        }    
        llamadaApiToken(data, url,respuesta,ErrorGeneral,history,source);
    } 
    if (idusu===1) {
        getSocialSend()
    }
}