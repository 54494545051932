import React,{useEffect, useState} from 'react';
import {Box,LinearProgress, Typography,FormControlLabel,Checkbox} from "@material-ui/core";
import MapaMarcadores from './MapaConsultaMarcador';
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import {ErrorGeneral, llamaApiCargaToken} from "../funciones/ClienteAxios";
import {generaCoordenadaDinam} from '../funciones/Mapas/FormatoCoord'
import Brightness1Icon from '@material-ui/icons/Brightness1';

export default function MapaPanelEstado ({estadoId}){
    const history = useHistory();
    const source = axios.CancelToken.source();
    const usuid =localStorage.getItem('UsuId') ;     
    const Dispvo = localStorage.getItem("Dispvo");
    const auxDispV = Dispvo.length >= 12 ? Dispvo : "451224452121";     
    const [espera,setEspera] = useState(true)
    const [poliLocal,setPoliLocal] = useState([])
    const [centro,setCentro] = useState({lat: 19.493815, lng: -99.1137317});
    const [cambiaMarcador,setCambiaMarcador] = useState(true);    
    const [banderaError,setBanderaError] = useState("");   
    const [listMapProm,setListMapProm] = useState([])     
    const [listApoyos,setListApoyos] = useState([]) 
    const [listRecorrido,setListRecorrido] = useState([]) 
    //const [ocultar,setCambiaMarcador] = useState(true); 
    const [checked, setChecked] = useState(false);
    const [checkApoyo, setCheckApoyo] = useState(false);
    const [checkRecorrido, setCheckRecorrido] = useState(false);
    useEffect(()=>{      
        limpiaCheck()                  
        getPoligonos();                        
        return ()=> {
            source.cancel();
        }            

    },[estadoId])

    useEffect(()=>{                                                      
        if (checked) 
        {   llamaMapAvance()    
        }
        else
        {   setListMapProm([])
        }        
    },[checked])

    useEffect(()=>{                                                      
        if (checkApoyo) 
        {   llamaMapApoyo()    
        }
        else
        {   setListApoyos([])
        }        
    },[checkApoyo])

    useEffect(()=>{                                                      
        if (checkRecorrido) 
        {   llamaRecorrido()    
        }
        else
        {   setListRecorrido([])
        }        
    },[checkRecorrido])
 
    const limpiaCheck=()=>{
        setChecked(false)
        setCheckApoyo(false)
        setCheckRecorrido(false)
        //console.log("despues del cambio");
    }
    
    const getPoligonos = ( ) => {
        setPoliLocal([])
        setCentro([])
        let data = qs.stringify({
            usuario: usuid, estado:estadoId,
            idDispositivo: 'csdq21weddewde' 
        });
        let url = "lugar-seccion-poligono-edo";

        function respuesta(auxiliar) 
        {      // console.log(auxiliar);         
            if (auxiliar[0]?.Id !== -1 && auxiliar[0].Lat!==0 &&auxiliar[0].Lon !==0) 
            {   
                
                let auxCoord=generaCoordenadaDinam(auxiliar[0].Lat, auxiliar[0].Lon)  
                let variable=0, arreRutas=[] ,arreRutaSeccion=[]   
                auxiliar.forEach((poligono) => {
                    if (variable!==poligono.Id) 
                    {
                      
                      if (arreRutas.length!==0) 
                      {
                        arreRutaSeccion.push({Id:variable,gps:arreRutas});                        
                        arreRutas=[]  
                      }                                                    
                      variable=poligono.Id;
                    }
                    if(poligono.Id===variable)
                    { 
                      let aux1=poligono.Lat;            
                      aux1=parseFloat(aux1)
                      let aux2=poligono.Lon;            
                      aux2=parseFloat(aux2)
                    // console.log(aux1,aux2 );
                      arreRutas.push({ Lat : aux1, Lon :aux2 })
                      
                    }
                      
                })
                setPoliLocal(arreRutaSeccion);   
                setCentro(auxCoord)   
                setBanderaError("")     
            }  
            else{
                setBanderaError("Estado sin Coordenadas")     
            }
        }    
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
    }
    
    const handleChange = () => {
        setChecked(!checked)
    };

    const onChangeApoyo = () => {
        setCheckApoyo(!checkApoyo)
    };

    const onChangeRecorrido = () => {
        setCheckRecorrido(!checkRecorrido)
    };

    const llamaMapAvance = () => {        
        let data = qs.stringify({
            Usuario:usuid,Edo:estadoId,Muni:0,
            Secc:0, Status:0,idDispositivo: auxDispV,
        });
        let url = "perfil-mapa-avance";

        function respuesta(auxiliar) {
            if (auxiliar[0].Id != -1) {
                return setListMapProm(auxiliar)
            }
        }    
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
    }
    
    const llamaMapApoyo = () => {        
        let data = qs.stringify({
            Usuario:usuid,Edo:estadoId,Muni:0,
            Secc:0, Status:0,idDispositivo: auxDispV,
        });
        let url = "estructura-mapa-avance";

        function respuesta(auxiliar) {
            if (auxiliar[0].Id != -1) {
                return setListApoyos(auxiliar)
            }
        }    
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
    }

    const llamaRecorrido = () => {        
        let data = qs.stringify({
            Usuario:usuid,Edo:estadoId,Muni:0,
            Secc:0, Status:0,idDispositivo: auxDispV,
        });
        let url = "recorrido/v2/mapa/avance";

        function respuesta(auxiliar) {
            if (auxiliar[0].Id != -1) {
                return setListRecorrido(auxiliar)
            }
        }    
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
    }

    const elementos = espera ? (<Box style={{marginTop:'2rem',marginLeft:'1em',width:'85%'}} > 
                                    <LinearProgress/> 
                                </Box>) 
                            :   banderaError.length === 0 ? 
                                
                                <MapaMarcadores poligonos={poliLocal}  centro={centro} zoom={8}
                                    cambiaMarcador={cambiaMarcador} setCambiaMarcador={setCambiaMarcador}                                     
                                    listMapProm={listMapProm} listApoyos={listApoyos} 
                                    listRecorrido={listRecorrido}
                                />
                                
                                :<Typography>{banderaError}</Typography>
                            ;
    
    return (
    <div style={{display:'flex',justifyContent:'center',marginTop:'.5rem'}}>  
    <Box>
        <Box display="flex">
            <Box  display="flex"  alignItems="center" >
                <FormControlLabel
                    disabled={espera} label="Promovidos" size={'small'}
                    control={<Checkbox color="primary" checked={checked} name={"checked"}/>}                
                    labelPlacement="end"  onChange={handleChange}
                />
                <Brightness1Icon fontSize="small" style={{color: "#48bd4c"}} />
            </Box>
         {/*    <Box display="flex"  alignItems="center" ml={2}>
                <FormControlLabel
                    disabled={espera} label="RG/RC" size={'small'}
                    control={<Checkbox color="primary" checked={checkApoyo} name={"checkApoyo"}/>}                    
                    labelPlacement="end" onChange={onChangeApoyo}
                /> 
                <Brightness1Icon fontSize="small" style={{color: "#aa1976"}} />
            </Box> */}
            <Box display="flex" alignItems="center" ml={3} >
                <FormControlLabel
                    disabled={espera} label="Recorridos" size={'small'}
                    control={<Checkbox color="primary" checked={checkRecorrido} name={"checkRecorrido"}/>}                    
                    labelPlacement="end" onChange={onChangeRecorrido}
                />
           
                {/* <Brightness1Icon fontSize="small" style={{color: "#182ac9"}} /> */}
                <img src='https://ecatepec.sfo3.cdn.digitaloceanspaces.com/ecatepec/recurso/marker123.svg' style={{width:"20px",height:"20px"}}/>
            </Box>
            <Box display="flex" alignItems="center" ml={15} >
                <Typography>Sin Coordenadas</Typography>
                <Brightness1Icon fontSize="small" style={{color: "#fb8006"}} />
            </Box>
        </Box>
        {elementos}
        </Box>  
    </div>
    );
}
