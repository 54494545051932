import Modal from '../generales/Modal'
import {Grid, TextField, MenuItem, makeStyles, Box, Typography} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import moment from "moment";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import axios from "axios";
import qs from "qs";
import {toast} from 'react-toastify';
import { useHistory } from "react-router-dom";
import { obtenDispo } from '../funciones/IdDispositivo';
import {llamaApiCargaToken, ErrorGeneral, llamadaApiToken} from '../funciones/ClienteAxios';
const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    }, root: {
        '& > svg': {
            fontSize: 18
        }, color: 'gray'
    }, h3: {
        color: 'rgb(15, 130, 238)', margin: 0
    }, textGray: {
        textAlign: 'justify'
    }
}));

export default function ModalSugerencia({ seleccionado, setModalAbierto, modalAbierto, listSuge, listStatus, listTipo, actualizar, setActualizar,}) {
    const classes = useStyles();    
    let history = useHistory();    
    const auxDispV = obtenDispo()          
    const usuID = localStorage.getItem('UsuId');    
    const source = axios.CancelToken.source();
    const [type, setType] = useState("");
    const [tipoDet, setTipoDet] = useState("");
    const [status, setStatus] = useState("");    
    const [registro, setRegistro] = useState({notaTipo:"", notaStatus:""});    
    const [btnDeshabilitado, setBtnDeshabilitado] = useState(true);
    const [cargando, setCargando] = useState(false);
    const [listTipoDet, setListTipoDet] = useState([]);
    const [mensaje, setMensaje] = useState("");
    const [aviso, setAviso] = useState(false);
    const [aux, setAux] = useState(0);
    const [aux2, setAux2] = useState(0);      
    const {notaTipo, notaStatus} = registro
     
    useEffect(() => {
        setType(seleccionado.TipoId)
        setStatus(seleccionado.StatusId)
        setTipoDet(seleccionado.TipoDtId)
        if (seleccionado.TipoId!==0) {
            getListTipoDet(seleccionado.TipoId)    
        }
        
        return () => {  
            source.cancel();        
        };
    }, []);

    useEffect(() => {
        return () => {
            if (notaTipo.length!==0) {
                setBtnDeshabilitado(false);
                setAux(1)
            } 
        };
    }, [notaTipo]);

    useEffect(() => {
        return () => {
            if (notaStatus.length!==0) {
                setBtnDeshabilitado(false);
                setAux2(1)
            } 
        };
    }, [notaStatus]);

    useEffect(() => {
        if (seleccionado.TipoId !== type && type !==0 && type!=="") {
            getListTipoDet(type)
        }
        return () => {
            if (type === listTipo[0]) {
                
                setBtnDeshabilitado(true);
            } else {
                setBtnDeshabilitado(false);
            }
        };
    }, [type]);
    
    useEffect(() => {
        return () => {
            if (status === listStatus[0]) {
                setBtnDeshabilitado(true);
            } else {
                setBtnDeshabilitado(false);
            }
        };
    }, [status]);

    const getListTipoDet = (auxLlave) => {            
        const data = qs.stringify({
            usuario:usuID, llave:auxLlave,                               
            idDispositivo: auxDispV,
        });
        const url = "solicitud/tipo/detalle";
        function respuestaListTipo(auxiliar) {
            if (auxiliar[0].Id !== -1 ) {             
                setListTipoDet(auxiliar);      
            }
        }
        llamadaApiToken(data, url, respuestaListTipo,ErrorGeneral,history, source);
    };

    const getEditTipo = () => {        
        const data = qs.stringify({
            usuario: usuID,
            solicitud: seleccionado.Id,
            tipo: tipoDet,
            nota:notaTipo,
            idDispositivo: auxDispV,
        });
        const url = 'solicitud/v2/edit/tipo';
        function respuesta(auxiliar) {
            if (auxiliar.respuesta !== -1) {
                toast.success('Categoría editada correctamente', {
                    position: "top-right", autoClose: 5000,
                    hideProgressBar: false, closeOnClick: true,
                    pauseOnHover: true, draggable: true, progress: undefined,
                });
            }else{
                toast.error('Ocurrio un error, intentelo mas tarde', {
                    position: "top-right", autoClose: 5000,
                    hideProgressBar: false, closeOnClick: true,
                    pauseOnHover: true, draggable: true, progress: undefined,
                });
            }
            let auxlistArray=listSuge             
            const pos =auxlistArray.findIndex((pefl)=>pefl.Id===seleccionado.Id)      
            const filtro1= listTipo.filter((tipo)=>tipo.Id ===type)       
            const filtro2= listTipoDet.filter((det)=>det.Id ===tipoDet)       
            const arrEdit={...seleccionado, TipoDtId: tipoDet, TipoDtNom:filtro2[0].Nom, 
                TipoId:type, TipoNom:filtro1[0].Nom, TipoColor:filtro1[0].Color}
            auxlistArray.splice(pos, 1,arrEdit )  
            //setListSuge(auxlistArray)
        }
        //console.log("se actualizo");
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando,history,source);
    }; 
    
    const getEditStatus = () => {        
        const data = qs.stringify({
            usuario: usuID,
            solicitud: seleccionado.Id,
            status: status,
            nota:notaStatus,
            idDispositivo: auxDispV,
        });
        const url = 'solicitud/edit/status';
        function respuesta(auxiliar) {
            if (auxiliar.respuesta !== -1) {
                toast.success('Estatus editado correctamente', {
                    position: "top-right", autoClose: 5000,
                    hideProgressBar: false, closeOnClick: true,
                    pauseOnHover: true, draggable: true, progress: undefined,
                });
            }else{
                toast.error('Ocurrio un error, intentelo mas tarde', {
                    position: "top-right", autoClose: 5000,
                    hideProgressBar: false, closeOnClick: true,
                    pauseOnHover: true, draggable: true, progress: undefined,
                });
            }
            let auxlistArray=listSuge             
            const pos =auxlistArray.findIndex((pefl)=>pefl.Id===seleccionado.Id)      
            const filtro1= listStatus.filter((stat)=>stat.Id ===status)                   
            const arrEdit={...seleccionado, StatusId:status, StatusNom:filtro1[0].Nom, StatusColor:filtro1[0].Color}
            auxlistArray.splice(pos, 1,arrEdit )  
            //setListSuge(auxlistArray)
        }
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando,history,source);
    }; 

    const handleChangeTipo = (event) => {
        setType(event.target.value);
        setTipoDet("")
        setAux(1)
    };

    const onChangeTipoDet = (event) => {
        setTipoDet(event.target.value);
        setAux(1)
    };

    const handleChangeStatus = (event) => {
        setStatus(event.target.value);
        setAux2(1)
    };

    const onChange = e =>
	{   let expNopermitida = new RegExp('[{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');             
        let expCadena = new RegExp('[A-Za-z0-9$.@%#_&-+()/:;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
                && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
                || e.target.value===' '|| e.target.value==='') 
        {
            setRegistro({ ...registro,   [e.target.name] : e.target.value })            
        }		
	};   

    const editarRegistro = async () => {   
        let bandera=false 
       // console.log(tipoDet);    
        if (aux !== 0) {
            if (tipoDet!=="") 
            {   bandera=true
                
                getEditTipo()           
            }
            else{
                const auxMensaje="Debe seleccionar un Detalle"
                toast.warning(auxMensaje, {
                    pauseOnFocusLoss: false, toastId: `nvo-perfil${7}`,
                })
            }
            
        }
        if (aux2 !== 0) {
            bandera=true
            getEditStatus()            
        }        
        if (bandera) {
            setModalAbierto(false);
            setActualizar(!actualizar);    
        }
        
    }

    function guardarDatos(e) {
        e.preventDefault()
        try {
            editarRegistro();
        } catch (error) {
            toast.error('Ocurrio un error, intentelo mas tarde', {
                position: "top-right", autoClose: 5000,
                hideProgressBar: false, closeOnClick: true,
                pauseOnHover: true, draggable: true, progress: undefined,
            });
        }
    }
    const auxTitu=` ${moment.utc(seleccionado.Fnac).format('DD/MM/YYYY')} ${seleccionado.Nombre}`
    return (
    <Modal titulo={auxTitu} modalAbierto={modalAbierto}
        setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
        mensaje={''} tipoAdvertencia={''} aviso={aviso}
        setAviso={setAviso} tamanio='sm' btnDeshabilitado={btnDeshabilitado}
        cargando={cargando}
        subtitulo={`${seleccionado.MunNom} Secc ${seleccionado.SeccNum}`  }
    >
        <div className={classes.margin}>
            <Grid container  alignItems='center' justifyContent='center'>
                <Grid item md={12}>
                     <Grid item md={12} className={classes.margin}>
                        <Typography variant='body2'>{seleccionado.Dir}</Typography>
                    </Grid>
                    <p className={classes.textGray}>{seleccionado.Mensaje}</p>
                    <Grid container>
                        {seleccionado.Email ? <>
                            <Grid item md={1} className={classes.root}>
                                <MailOutlineIcon color="primary"/>
                            </Grid>
                            <Grid item md={11} className={classes.textGray}>
                                {seleccionado.Email}
                            </Grid>
                        </> : <></>}
                        {seleccionado.Tel ? <>
                            <Grid item md={1} className={classes.root}>
                                <PhoneIcon color="primary"/>
                            </Grid>
                            <Grid item md={11} className={classes.textGray}>
                                {seleccionado.Tel}
                            </Grid>
                        </> : <></>}
                    </Grid>
                    <Grid item md={12} className={classes.margin}>
                        <TextField id="select-tipo" select fullWidth label="Tipo"
                            value={type} onChange={handleChangeTipo}
                        >
                            {listTipo.map((tipo) => (
                                <MenuItem key={tipo.Id} value={tipo.Id}>
                                    {tipo.Nom}
                                </MenuItem>))}
                        </TextField>
                       {listTipoDet.length!==0?
                        <TextField id="select-tipo-det" select fullWidth label="Detalle"
                            value={tipoDet} onChange={onChangeTipoDet}
                            style={{marginTop:"1rem"}}
                        >
                            {listTipoDet.map((tipo) => (
                                <MenuItem key={tipo.Id} value={tipo.Id}>
                                    {tipo.Nom}
                                </MenuItem>))}
                        </TextField>
                        :null}
                    </Grid>
                    
                    <Grid style={{marginTop:"1rem"}}>
                        <TextField id="nota-tipo" fullWidth label="Nota Tipo"  variant="outlined"
                          multiline maxRows={4}      inputProps= {{ maxLength: 1000 }} name='notaTipo'
                            value={notaTipo} onChange={onChange} />
                    </Grid>
                    <Grid item md={12} className={classes.margin}>
                        <TextField id="select-status" select fullWidth label="Estatus"
                            value={status} onChange={handleChangeStatus}
                        >
                            {listStatus.map((status) => (
                                <MenuItem key={status.Id} value={status.Id}>
                                    {status.Nom}
                                </MenuItem>))}
                        </TextField>
                    </Grid>
                    <Grid style={{marginTop:"1rem"}}>
                    <TextField id="nota-Status" fullWidth label="Nota Estatus" variant="outlined"
                      multiline maxRows={4}      inputProps= {{ maxLength: 1000 }} name='notaStatus'
                            value={notaStatus} onChange={onChange} />
                    </Grid>
                </Grid>
                <Grid item md={4}>
                    <Grid container spacing={1}>
                      
                   
                    </Grid>
                </Grid>
            </Grid>
        </div>
    </Modal>)
}