import React, { useState } from "react";
import {  //Box,LinearProgress,
  IconButton, Table, TableBody,
  TableCell, TableContainer, TableHead,
  TableRow, Card, Tooltip,
  TablePagination,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import XLSX from 'xlsx';
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import EditIcon from "@material-ui/icons/Edit";
import PaginacionTab from "../layout/PaginacionTab";
import {numeroPag} from "../funciones/Paginacion";
import {findNumberCad} from '../funciones/FindNumber'
import SaveIcon from '@material-ui/icons/Save';
import MailIcon from '@material-ui/icons/Mail';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
const useStyles = makeStyles({
  
  container: {
    //maxHeight: 550,
    maxHeight: "7rem",
    minHeight: 150,
    width: "28rem",
  },
  container2: {
    maxHeight: "15rem",
    //maxHeight: window.innerHeight-170,
    //minHeight: 280,
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: process.env.REACT_APP_Color_Second,
    },
  },
  tableCell: {
    "$selected &": {
      color: "white",
    },
  },
  hover: {},
  selected: {},
});

const columnas = [
  { id: 1, titulo: "Fecha", alinear: "center", }, 
  { id: 14, titulo: "Asignado ",alinear: "center",  },
  { id: 10, titulo: "Contenido", alinear: "center",  },

];

const TablaListCarta = ({  listSuge, listExcel,}) => {
  const classes = useStyles();
  let history = useHistory();
  const usuId =localStorage.getItem('UsuId') ? parseInt(localStorage.getItem('UsuId')):0;     
  const source = axios.CancelToken.source();
  const cadena = localStorage.getItem("Operacion")+"|"; 
  const fondo =process.env.REACT_APP_Fondo_Color
  const tamMax = 100
  const [datos, guardarDatos] = useState([]);
  const [sinDatos, guardarSinDatos] = useState(true);
  const [espera, setEspera] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const [regisSelect, setRegisSelect] = useState([]);
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [numPagina, setNumPagina] = useState(0);
  
  React.useEffect(() => {
    
    if (listSuge.length!==0 &&listSuge[0].Id!=="0" &&listSuge[0].Id!==0 ) {
      numeroPag(listSuge, tamMax, setTotalPaginas, setNumPagina, guardarDatos)
      //guardarDatos(listSuge);  
    }else{
      guardarDatos([]);
    }
    
    //console.log(listSuge);
    return () => {
      source.cancel();
    };
  }, [listSuge]);

  const seleccionado = (auxId, auxOrden) => {
    setSelectedID(auxId);
    setRegisSelect(auxOrden);

    //Window.open(auxOrden.Link)
  };

  const celda = (alinear, estilo, contenido) => {
    return (
    <TableCell align={alinear} className={classes.tableCell}
      padding={"none"} style={estilo}
    >
      {contenido}
    </TableCell>
    );
  };

  function Renglon(props) {
    const { row } = props;
 
    const auxFReg =
      moment.utc(row.Fch).format("YYYY-MM-DD HH:mm") === "1900-01-01 00:00"
        ? ""
        : moment.utc(row.Fch).format("DD/MM/YYYY HH:mm");
    const auxInfo= <Tooltip title={`Descargar`}>
        <IconButton   aria-label="add circle" size="small" 
          onClick={() =>     window.open(row.Link)} component="span">
          {selectedID === row.Id ? 
            <CloudDownloadIcon style={{color:'white',width:18+'px'}} />
            :<CloudDownloadIcon style={{width:18+'px'}} color="primary"/>
          }
        </IconButton>                     
        </Tooltip>
    return (    
    <TableRow
      onClick={() => { seleccionado(row.Id, row) }}
      className={classes.tableRow} selected={regisSelect?.Id === row.Id}
      classes={{ hover: classes.hover, selected: classes.selected }}
    >
      {celda("center", { width: "40px", fontSize: "10px", paddingRight: "2px" }, auxInfo )} 
      {celda("center", { width: "60px", fontSize: "10px", paddingRight: "2px",fontWeight:"700" }, auxFReg )} 
      {celda("center", { width: "70px", fontSize: "10px", paddingRight: "2px",fontWeight:"700" }, row.Usr )}             
      {celda("left",{ width: "240px", fontSize: "12px", paddingLeft: "8px" }, row.Descr)} 
    </TableRow>  
    );
  }

  const vacia = (
    <TableCell className={classes.tableCell} align="center">
      {" "}
    </TableCell>
  );
  const ColumnaVacia = (
    <TableCell
      className={classes.tableCell}
      align="center"
      style={{ backgroundColor: fondo, color: "white" }}
    >
      {" "}
    </TableCell>
  );

  const exportarArch = () => {
    let today = new Date();                    
    let fec = moment(today);        
    //console.log(listaExcel);
    const ws = XLSX.utils.aoa_to_sheet(listExcel);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Reporte");
    XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+" Lista Solicitudes.xlsx")
  }; 

  function tablaCompleta(auxlista) {
    return (
    <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {/* usuId ===1?ColumnaVacia:null */}
            {/* findNumberCad('|36|',cadena)?
            <TableCell align="center" padding={'none'} style={{ backgroundColor:fondo,color:'white'}}  >     
              <IconButton onClick={exportarArch} size="small" >
                <Tooltip title="Exportar a Excel">
                  <SaveIcon style={{color:'white'}} size="small" />
                </Tooltip>
              </IconButton>                                  
            </TableCell>:ColumnaVacia  */}              
              {ColumnaVacia}
            {columnas.map((row) => {
              return (
                <TableCell
                  style={{ backgroundColor: fondo, color: "white" }}
                  key={row.titulo + row.id}
                  align={row.alinear}
                  padding={"none"}
                >
                  {row.titulo}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {auxlista.length != 0
            ? auxlista.map((row) => (
                <Renglon key={"regPerfilGen" + row.Id} row={row} />
              ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
    );
  }

  function onChangePagina(event, newPage) {
    event.preventDefault();
    if (newPage === 0) {
        guardarDatos(listSuge.slice(0, tamMax))
    } else {
      guardarDatos(listSuge.slice(newPage * tamMax, newPage * tamMax + tamMax))
    }
    setNumPagina(newPage)
  }

  function generarFooter() {
    return (
    <TablePagination style={{padding:0}}
      rowsPerPageOptions={false} colSpan={3}
      rowsPerPage={tamMax} count={listSuge.length}
      page={numPagina} lang='es'
      labelDisplayedRows={
        ({from, to, count}) => {
          return `${from}-${to} de ${count}`
        }
      }
      onPageChange={onChangePagina}
      ActionsComponent={PaginacionTab}/>)
  }

  const tablaSinDatos = () => {
    return (
    <TableContainer className={classes.container2}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>              
            {columnas.map((row) => {
              return (
                <TableCell
                  style={{ backgroundColor:fondo, color: "white" }}
                  key={row.titulo + row.id}
                  align={row.alinear}
                  padding={"none"}
                >
                  {row.titulo}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.tableRow}>
            {vacia}
            {vacia}              
            <TableCell className={classes.tableCell} align="center">
              Sin{" "}
            </TableCell>
            {vacia}
            {vacia}
            <TableCell className={classes.tableCell} align="center">
              Registros
            </TableCell>
            {vacia}
            {vacia}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    );
  };

  const contenido = datos.length !== 0 ? tablaCompleta(datos) : tablaSinDatos();
  //const tabla = espera ?  (<Box pt={3}><LinearProgress/> </Box>) :( contenido )
 
  const paginacion = !espera && datos.length !== 0 && totalPaginas > 1 ? generarFooter() : null;
  return (
  <div style={{ minWidth: 24 + "rem", maxWidth: "28rem" }}>
    {paginacion}
 
    {contenido}
 
  </div>
  );
};

export default TablaListCarta;
