import {makeStyles} from "@material-ui/core/styles";
import Modal from "../generales/ModalCerrarActualiza";
import {useEffect, useState} from "react";
import {FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, TextField} from "@material-ui/core";
import qs from "qs";
import {ErrorGeneral, llamaApiCargaToken,} from "../funciones/ClienteAxios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment/moment";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import {toast} from "react-toastify";
import SelectEdo from '../generales/SelectEdo';

const useStyles = makeStyles((theme) => ({}));

const NvoComercio = ({abrirModal, setAbrirModal, idusu, history, source, latitud, longitud,actualiza}) => {

    const classes = useStyles();
    let usuTipo = localStorage.getItem('Tipo');
    let fecha = Date.now()
    const [selectedDate, handleDateChange] = useState(fecha);
    const [listMun, setListMun] = useState([])
    const [listSecc, setListSecc] = useState([])
    const [auxListSecc, setAuxListSecc] = useState([])
    const [listTipo, setListTipo] = useState([])
    const [listCP, setListCP] = useState([])
    const [listColonia, setListColonia] = useState([])
    const [listGiro, setListGiro] = useState([])
    const [nuevoRegistro, setNuevoRegistro] = useState({
        Estado: process.env.REACT_APP_EDO,
        Municipio: 34, Seccion: '', Tipo: '', Latitud: latitud,
        Longitud: longitud, Fecha: '', Direccion: '', Observacion: '',
        Giro:"", Nombre:"", Alias:"", Calle:"", NoExt:"",
        NoInt:"", Colonia:"", Cp:"", Manual:0, ManualDir:"",
        Cantidad:0,
    });
    const {Estado, Municipio, Seccion, Tipo, Longitud, Latitud, Fecha, Direccion, Observacion,
        Giro, Nombre, Alias, Calle, NoExt, NoInt, Colonia, Cp, Manual, ManualDir,
        Cantidad
    } = nuevoRegistro
    const [btnDeshabilitado, setBotonDeshabilitado] = useState(false)
    const [aviso, setAviso] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [cargando, setCargando] = useState(false);

    const handleClose = () => {
        setAbrirModal(false)
        setAviso(false);
    }

    useEffect(() => {
      
        getListGiro()
        getListCP()
        if (listSecc.length === 0) {
            getListSecciones()
        }

    }, []);

    useEffect(() => {
      
        
        if (Cp.length !== 0) {
            getListColonia(Cp)
        }

    }, [Cp]);

    const getListGiro = () => {
        const data = qs.stringify({
            Usuario: idusu,            
            idDispositivo: 'w8rf51v21dsd2cs',
        });
        const url = "comercio/v1/giro";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListGiro(response)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const getListCP = () => {
        const data = qs.stringify({
            usuario: idusu,            
            idDispositivo: 'w8rf51v21dsd2cs',
        });
        const url = "lugar/v2/cp";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListCP(response)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const getListColonia = (auxCp) => {
        const data = qs.stringify({
            usuario: idusu,      
            cp:auxCp,      
            idDispositivo: 'w8rf51v21dsd2cs',
        });
        const url = "lugar/v2/cp/colonia";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListColonia(response)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const getListSecciones = () => {
        const data = qs.stringify({
            usuario: idusu,municipio:34,
            idDispositivo: 'w8rf51v21dsd2cs',
        });
        const url = "lugar-seccion";

        function respuesta(response) {
            if (response[0].Id != -1) {
                setListSecc(response)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    };

    const guardarSeccionSelect = (e) => {
        if (e !== null && e !== undefined) {
            setNuevoRegistro({
                ...nuevoRegistro,
                Seccion: e.target.value
            })
            setMensaje('');
            setAviso(false);
        } else {
            setNuevoRegistro({
                ...nuevoRegistro,
                Seccion: ""
            })
        }
    }

    const onChange = e => {
        setNuevoRegistro({...nuevoRegistro,[e.target.name]: e.target.value})
    }

    const onChangeCp = (e) => {
        if (e !== null && e !== undefined) {
            setNuevoRegistro({
                ...nuevoRegistro,
                Cp: e.target.value,
                Colonia: "", 
            })
            setMensaje('');
            setAviso(false);
        } else {
            setNuevoRegistro({
                ...nuevoRegistro,
                Cp: "", Colonia: "", 
            })
        }
    }

    const onChangeEdo = (e) => {
        if (e !== null && e !== undefined) {
            setNuevoRegistro({
                ...nuevoRegistro,
                Estado: e.target.value,
                Municipio: "", Seccion: ""
            })
            setMensaje('');
            setAviso(false);
        } else {
            setNuevoRegistro({
                ...nuevoRegistro,
                Estado: "",
                Municipio: "", Seccion: ""
            })
        }
    }

    const setNewComercio = () => {
        setCargando(true)
       // setBotonDeshabilitado(true);

        const data = qs.stringify({
            Usuario: idusu,
            Edo: Estado,
            Muni: Municipio,
            Secc: Seccion,
            fecha: moment(selectedDate).format("YYYY-MM-DD") + 'T00:00:00',            
            Observ: Observacion,
            latitud: Latitud,
            longitud: Longitud,                                    
            Giro:Giro,
            Nombre:Nombre,
            Alias:Alias,
            Calle:Calle,
            NoExt:NoExt,
            NoInt:NoInt,
            Colonia:Colonia,
            Cp:Cp!=="" ? Cp:0,
            Manual:Manual,
            ManualDir:ManualDir,
            Cantidad:Cantidad,
            AntLat:0.0,
            AntLon:0.0,
            idDispositivo: '45vf2d1vdf21d2f'
        });

        const url = "comercio/v1/new";

        function respuesta(response) {
            if (response.respuesta === 1) {
                setAviso(true)
                toast.success('Comercio agregado correctamente', {
                    pauseOnFocusLoss: false,
                    toastId: `nvo-Comercio${1}`
                })
                actualiza()
                setAbrirModal(false)
            } else {
               // setBotonDeshabilitado(false);
                toast.error('Datos incorrectos, intente mas tarde', {
                    pauseOnFocusLoss: false,
                    toastId: `error-Comercio${1}`
                })
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargando, history, source);
    }

    const verificaNulos = (campo,descripcion)=>{
        let respuesta =false;        
          
        if( (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;        
          //  console.log(campo+' '+descripcion);                  
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }

    function guardarDatos(e) {
        e.preventDefault()
        if ( !verificaNulos(Seccion,"Sección")  
             ) 
        {   //console.log("prueba",nuevoRegistro);
            setAviso(false);
            setNewComercio()
           // console.log( moment(selectedDate).format("YYYY-MM-DD") + 'T00:00:00');
        }  
    }

    return (
    <Modal
        titulo={'Nuevo Registro'} modalAbierto={abrirModal} mensaje={mensaje}
        setModalAbierto={setAbrirModal} guardarDatos={guardarDatos}
        tipoAdvertencia={tipoAdvertencia} aviso={aviso} tamanio={'sm'}
        btnDeshabilitado={btnDeshabilitado} setAviso={setAviso}
        cargando={cargando} cerrarBorrar={handleClose}
    >
        <Grid container alignItems={'center'} spacing={1}>
           
    
            <Grid item xs={4}>
                {listSecc.length !== 0 ?
                    <FormControl size='small'>
                        <InputLabel size='small' variant="outlined" id="Seccion-label-nvo">Sección</InputLabel>
                        <Select size='small' variant="outlined" style={{width: '8rem'}}
                            label="Sección" id="Seccion-select2" name="Seccion"
                            value={Seccion} onChange={guardarSeccionSelect}
                            fullWidth
                        >
                            {listSecc.map((seccion, index) => {
                                return (
                                <MenuItem size='small' value={seccion.Id} key={seccion.Id}>
                                    <em>{seccion.Numero}</em>
                                </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                :   null
                }
            </Grid>
            
            <Grid item xs={4}>
                <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                    <DatePicker
                        disableFuture label="Fecha"
                        format="dd/MM/yyyy" size="small"
                        views={["year", "month", "date"]}                                                
                        minDate="2017-03-14" maxDate={new Date()}
                        value={selectedDate} onChange={handleDateChange}
                        style={{width: 6.5 + 'rem'}}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
            
            {listGiro.length !== 0 ?
                <FormControl size="small">
                    <InputLabel htmlFor="GiroInp" size="small" variant="outlined" >Giro</InputLabel>
                    <Select   value={Giro} fullWidth onChange={onChange} style={{minWidth:"12rem"}}
                        name='Giro' id='GiroSlct' size="small" variant="outlined" 
                    >
                        {listGiro.map(element => {
                            return(
                            <MenuItem size='small' value={element.Id} key={element.Id}>
                                <em>{element.Nom}</em>
                            </MenuItem>)                            
                        })}
                    </Select>
                </FormControl>
                : null
            }
            </Grid>
            <Grid item xs={4} style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>
                <TextField id="NombreTxt" name="Nombre" label="Nombre" fullWidth size="small"
                    value={Nombre} onChange={onChange} variant="outlined"/>
            </Grid>
            <Grid item xs={4} style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>
                <TextField id="AliasTxt" name="Alias" label="Alias" fullWidth size="small"
                    value={Alias} onChange={onChange} variant="outlined"/>
            </Grid>
            <Grid item xs={4} style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>
                <TextField id="CalleTxt" name="Calle" label="Calle" fullWidth size="small"
                    value={Calle} onChange={onChange} variant="outlined"/>
            </Grid>
            
            <Grid item xs={4} style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>
                <TextField id="NoExtTxt" name="NoExt" label="NoExt" fullWidth size="small"
                    value={NoExt} onChange={onChange} variant="outlined"/>
            </Grid>
            <Grid item xs={4} style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>
                <TextField id="NoIntTxt" name="NoInt" label="NoInt" fullWidth size="small"
                    value={NoInt} onChange={onChange} variant="outlined"/>
            </Grid>
            <Grid item xs={4} style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>
            {listCP.length !== 0 ?
                <FormControl size="small">
                    <InputLabel htmlFor="CpInp" size="small" variant="outlined" >C.P.</InputLabel>
                    <Select   value={Cp} fullWidth onChange={onChange} style={{minWidth:"11rem"}}
                        name='Cp' id='CpSlct' size="small" variant="outlined" 
                    >
                        {listCP.map(element => {
                            return(
                            <MenuItem size='small' value={element.Cp} key={element.Cp}>
                                <em>{element.Cp}</em>
                            </MenuItem>)                            
                        })}
                    </Select>
                </FormControl>
                : null
                }
                 
            </Grid>
            <Grid item xs={10} style={{marginBottom:.5+'rem',marginTop:.5+'rem'}}>
                {listColonia.length !== 0 ?
                <FormControl size="small">
                    <InputLabel htmlFor="ColoniaInp" size="small" variant="outlined" >Colonia</InputLabel>
                    <Select   value={Colonia} fullWidth onChange={onChange} style={{minWidth:"12rem"}}
                        name='Colonia' id='ColoniaSlct' size="small" variant="outlined" 
                    >
                        {listColonia.map(element => {
                            return(
                            <MenuItem size='small' value={element.Col} key={element.Col}>
                                <em>{element.Col}</em>
                            </MenuItem>)                            
                        })}
                    </Select>
                </FormControl>
                : null
                }                
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="Observacion" name="Observacion"
                    label="Observación" variant="outlined"
                    multiline fullWidth onChange={onChange}
                    rows={4} value={Observacion}
                />
            </Grid>
        </Grid>
    </Modal>);
};

export default NvoComercio;
