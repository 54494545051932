import React, {useState, useEffect} from 'react';
import {Box,Select,InputLabel,MenuItem, LinearProgress, Typography,Paper} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import Graficas from './GraficasAvSecc';
import axios from 'axios';
import qs from 'qs';
import ModalInformativo from '../generales/ModalInformativo';
 

const useStyles = makeStyles((theme) => ({
  imageSize: {
    //width: '50%', 
    height: '20vh'
}, container: {
    overflowY: 'auto', maxHeight: window.innerHeight - 240, marginTop: '.5rem'
}, content: {
    padding: '0!important', margin: 0
}
  }));

export default function ModalTotSecc({titulo,modalAbierto,setModalAbierto,seleccionado,listTipos}){
 
  const source = axios.CancelToken.source(); 
 
  useEffect(()=>{                 
  
    return ()=> {
      source.cancel();
  }
  },[   ]);

 
  return (
  <ModalInformativo
    setModalAbierto={setModalAbierto} modalAbierto={modalAbierto}
    titulo={titulo} tamanio={'md'} maxTam={false} > 
 
    <Box display="flex" justifyContent="center" >
        <Graficas valor={seleccionado} listTipos={listTipos} />
    </Box>
  </ModalInformativo>
  )
}