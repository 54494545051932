import React,{useState,useEffect} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
        Card,Box, Typography,TablePagination} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PaginacionTab from '../layout/PaginacionTab'
const useStyles = makeStyles({
    estiloCard:{
      width: '36rem',
      boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
      // color:'#4a4a4a',
      display:'block',
      padding:'.5rem',
      maxHeight:'100%',
      minHeight: 280,
      marginRight:'.5rem'
    },
    tablaContainer: {
      width: '35.5rem',
      maxHeight: '24.5rem',
      minHeight: 280,
      //minWidth:'100%',
    },
    totalEstilo:{
      fontSize:'1.25rem',
      fontWeight:'bold'
    },
    tituloEstilo:{
      paddingBottom:'.25rem',
      fontWeight:'bold'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor:process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });

 

export default function TablaAgrupamiento({listAv,registro, setRegistro,tituloDirecc}){    
  const classes = useStyles();      
  const [datos,setDatos] = useState([]);     
  const [totalGen,setTotalGen] = useState([]);    
  const [totalPaginas,setTotalPaginas]=useState(1);
  const [numPagina,setNumPagina]=useState(0);
  const auxCol=process.env.REACT_APP_Fondo_Color  
  useEffect(()=>{    
    //console.log(listAv);
    if (listAv.length!==0 &&listAv[0].agrupamiento ) 
    { 
      //listAv.sort(function (a, b){ return b.Total- a.Total      });
      let aux={totHom:0, GenX:0, totMuj:0, avGen:0, MetaGen:0}
      /* listAv[0].agrupamiento.forEach(element => {
        aux={ totHom: aux.totHom + element.GenH,
          totMuj: aux.totMuj + element.GenM,
          GenX: aux.GenX + element.GenX,
          avGen: aux.avGen + element.Avc,
          MetaGen: aux.MetaGen + element.Meta
        }
      }); */
      if (listAv[0].agrupamiento.length>300) {
       
      let auxTam = listAv[0].agrupamiento.length;
      if(auxTam%300===0)
      {
          let pagina=Math.trunc(auxTam/300)
          setTotalPaginas(pagina)   
      }
      else
      {   let pagina=Math.trunc(auxTam/300)
          pagina=pagina+1;
          setTotalPaginas(pagina)
      }
      setNumPagina(0) 
      setDatos(listAv[0].agrupamiento.slice(0,300))
     
      //setDatos(listAv[0].agrupamiento)  
     
      }
      else{
        setTotalPaginas(1)
        setNumPagina(0)        
        setDatos(listAv[0].agrupamiento)          
      }
      setTotalGen(aux) 
    }
    else
    { setDatos([])            
    }  
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[listAv])          

  const onChangeTable =(regis)=>{       
    setRegistro(regis)                           
  }

  const celda = (alinear, estilo, contenido,index) => {
    return (
    <TableCell align={alinear} key={index} className={classes.tableCell} 
      padding={'none'} style={estilo} >
      {contenido}
    </TableCell>)
  }

  const CelTitl=({index,titulo,aline,estilo})=> celda(aline?aline:"center", {...estilo,backgroundColor:auxCol,color:'white',}, titulo?titulo:"",index) 

  const colorStatus=(status)=>{
    let stdColor=""
    if (status<=4) 
    { stdColor="#FBBE06"      
    }
    else
    { if (status<=8) 
      {
        stdColor="#f07111"      
      }
      else
      {        
        stdColor="#E91E63"                                         
      } 
    }
    return(
    <Box display={"flex"}  justifyContent="flex-end" style={{textAlign:"right",alignItems:"center",fontSize:"12px"}}>            
      {`${status}`}
      <FiberManualRecordIcon fontSize="small" style={{color:stdColor,}}/>      
    </Box>)
  }  

  const formatoDir=(registro)=>{
    let respuesta=`${registro.Col}, ${registro.Calle}`
    if (registro.NoExt!=="") {
      respuesta=`${respuesta} NoExt ${registro.NoExt}`
    }
    
    if (registro.NoInt!=="") {
      respuesta=`${respuesta} NoInt ${registro.NoInt}`
    }
    return primerformato(respuesta)
  }

  const primerformato = str => {
    return str.toLowerCase().replace(/\b[a-z]/g, c => c.toUpperCase())
  }

  function TablaEstr(auxDatos) {        
    
    return(        
    <TableContainer className={classes.tablaContainer} id="tamTablaReg"  >
    <Table stickyHeader aria-label="sticky table">
      <TableHead >    
        { totalGen.length!==0 ?
        <TableRow>
         {/*  <CelTitl index={1} titulo={ "Colonia" } aline={"left"} estilo={{fontSize:"13px"}} />        */}  
          <CelTitl index={2} titulo={tituloDirecc} aline={"center"} estilo={{fontSize:"13px"}} />                                    
          <CelTitl index={4} titulo={
            <span  style={{fontSize:"12px"}}>Manzana <br/><strong>{listAv[0].agrupamiento.length}</strong></span> } aline={"center"} />   
          <CelTitl index={4} titulo={
            <span  style={{fontSize:"12px"}}>Total <br/><strong>{listAv[0].total[0].Total}</strong></span> } aline={"center"} />         
          <CelTitl index={5} titulo={<span style={{fontSize:"12px"}}>Hombre <br/><strong>{listAv[0].total[0].TotH}</strong></span>} 
            estilo={{fontSize:"13px"}} aline={"center"} />         
          <CelTitl index={6} titulo={<span style={{fontSize:"12px"}}>Mujer <br/><strong>{listAv[0].total[0].TotM}</strong></span>} 
            estilo={{fontSize:"13px"}} aline={"center"} />         
          <CelTitl index={7} titulo={<span style={{fontSize:"12px"}}>No Binario <br/> <strong>{listAv[0].total[0].TotX}</strong></span>} 
            estilo={{fontSize:"13px"}} aline={"center"} />                             
        </TableRow>
        :null }                   
      </TableHead>  
      <TableBody>         
      {auxDatos.map((elemnto,index) => {  
        const auxVal=registro?.Orden === elemnto.Orden ?true:false                                              
        return (                  
        <TableRow className={classes.tableRow} selected={ auxVal} 
          classes={{ hover: classes.hover, selected: classes.selected }} 
          key={index+1} onClick={ () => onChangeTable(elemnto) }  
        >   
          {/* celda("left", {width:"4rem", fontSize:"12px"},primerformato(elemnto.Col)  ,index+1)  */}        
          {celda("left", {width:"12rem", fontSize:"12px"}, formatoDir(elemnto) ,index+1) }         
          {celda("left", {width:"2rem", fontSize:"12px"}, elemnto.mza ,index+2) }          
          {celda("right", {width:"3rem", fontSize:"12px"}, colorStatus(elemnto.Total),index+4) }
          {celda("right", {width:"2.4rem", fontSize:"12px"}, elemnto.TotH,index+5) }
          {celda("right", {width:"2.4rem", fontSize:"12px"}, elemnto.TotM,index+6) }
          {celda("right", {width:"3.4rem", fontSize:"12px",paddingRight:".3rem"}, elemnto.TotX,index+7) }     
        </TableRow>              
        );                        
      })}                                                
      </TableBody>
    </Table>
    </TableContainer>        
    ); 
  }
  function onChangePagina(event, newPage){
    event.preventDefault();
    if (newPage ===0) 
    {   setDatos(listAv[0].agrupamiento.slice(0,300))   
    }
    else
    {   setDatos(listAv[0].agrupamiento.slice(newPage * 300, newPage * 300 + 300))   
    }
    setNumPagina(newPage)
}
  function generarFooter(){
    return(
    <TablePagination  style={{padding:0}}
      rowsPerPageOptions={false}
      colSpan={3} rowsPerPage={300}
      count={listAv[0].agrupamiento.length} page={numPagina}
      lang='es'  onPageChange={onChangePagina}
      ActionsComponent={PaginacionTab}
      labelDisplayedRows={
        ({ from, to, count }) => {
        return '' + from + '-' + to + ' de ' + count
        }
      }
    />)
  }         

  const paginacion = totalPaginas>1 ? generarFooter() : null
  const llamada= datos.length !== 0 ?(TablaEstr(datos)):(<Box style={{paddingTop:1+'rem'}}>
                                  <Typography variant="h5" component="h5">No contiene datos</Typography>
                                  </Box>)
                                                                                                                                           
  return (
  <Box display={'flex'} flexDirection="column" style={{height: '29rem' }}>
    
    <Card className={classes.estiloCard} style={{height:window.innerHeight-140}}>                        
      {paginacion}
      {/* <Typography variant="body1" component="body1"></Typography>  */}
      
      {llamada}   
    </Card>

  </Box>                    
  );
}