import React,{useEffect, useState} from 'react'
import {Box,IconButton,Table,TableBody,TableCell,LinearProgress,      
      TableContainer,TableHead,TableRow,Card,Tooltip,
      TablePagination,} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
import XLSX from 'xlsx';
import moment from 'moment';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ModalMapa from './ModalMapa'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import InfoPromo from './ModInfo';
import InfoIcon from '@material-ui/icons/Info';
import ModalPerfilv2 from '../nvoPerfil/NvoPerfilv2';
import ModalNvoPerfilImg from '../nvoPerfil/ModalNvoPerfImg';
import ModalAvisoPerfil from '../nvoPerfil/ModalAvisoPerfil';
import ImageIcon from '@material-ui/icons/Image';
//import SaveIcon from '@material-ui/icons/Save';
import CreateIcon from '@material-ui/icons/Create';
import ModalEditPerfil from '../EditarPerfil/ModalEditPerfil';
import PaginacionTab from '../layout/PaginacionTab'
import { numeroPag } from "../funciones/Paginacion";
import {ordenMapBx,ordenIdColor} from "../MapaEdo/OrdenxMapBox"
import {ErrorGeneral, llamaApiCargaToken,llamadaApiToken} from "../funciones/ClienteAxios";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { EnviarPerfil } from '../funciones/SendPerfil';
import { toast } from 'react-toastify'; 
const useStyles = makeStyles({
    root: {
      //width: '100%',
    },
    container: {
      //maxHeight: 550,
      maxHeight: window.innerHeight-252,
      minHeight: 280,
      maxWidth:'100%',
      minWidth:60+'rem',
    },
    container2: {
      maxHeight: 550,
      //maxHeight: window.innerHeight-170,
      //minHeight: 280,
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
  const columnas=[   
    { id:5, titulo:"Verificación",alinear:'center' },         
    { id:3, titulo:"Registro", alinear:'center' }, 
    { id:12,titulo:"Estado", alinear:'center' },
    { id:8,titulo:"Municipio", alinear:'center' },
    { id:7,titulo:"Sección", alinear:'center' },
    { id:6, titulo:"Teléfono",alinear:'center' }, 
    { id:10, titulo:"Usuario",alinear:'center' },
    { id:11, titulo:"Nombre",alinear:'center' },
    { id:13, titulo:"Referente", alinear:'center' }, 
    { id:9, titulo:"Género", alinear:'center' },        
    { id:4, titulo:"Nacimiento",alinear:'center' },       
  ];

const TablaPerfilGeneral = ({fSelect,actualizar,setActualizar,setArregloColor,listReferente, referente, setTotalPerfil,nombre,edo, setListGeoEdo, setTotalSecc}) => {
  const classes = useStyles();
  let history = useHistory(); 
  const source = axios.CancelToken.source();    
  const tamMax=150
  const usuID = localStorage.getItem('UsuId')?parseInt(localStorage.getItem('UsuId') ):0;
  const fondo=process.env.REACT_APP_Fondo_Color
  const colSecond=process.env.REACT_APP_Color_Second
  const usuTipo =localStorage.getItem('Tipo') ?parseInt(localStorage.getItem('Tipo') ):0;              
  const auxDispV = localStorage.getItem("Dispvo") && localStorage.getItem("Dispvo").length >= 12 ? localStorage.getItem("Dispvo") : "451224452121";
  const [datos, guardarDatos] = useState([])  
  const[sinDatos,guardarSinDatos] = useState(true)
  const [espera,setEspera] = useState(false);
  const [selectedID, setSelectedID] = useState(null);  
  const [regisSelect, setRegisSelect] = useState([]);
  const [modalNvoPerfil, setModalNvoPerfil] = useState(false);
  const [modalEditPerfil, setModalEditPerfil] = useState(false);
  const [modalNvoImg, setModalNvoImg] = useState(false);  
  const [infoMapaMod, setInfoMapaMod] = useState(false);
  const [subtInfo, setSubtInfo] = useState("")  
  const [tituloMapa,setTituloMapa] = useState("")
  const [modalInfo, setModalInfo] = useState(false);    
  const [listPlantilla,setListPlantilla]=useState([])
  const [modalAvisoImg, setModalAvisoImg] = useState(false);
  const [modalAvisoImg2, setModalAvisoImg2] = useState(false);
  const [nvoRegisPerfil, setNvoRegisPerfil] = useState([]);
  const [listaExcel, setListaExcel] = useState([]);
  const [totalPaginas,setTotalPaginas]=useState(0);
  const [numPagina,setNumPagina]=useState(0);
  const [listPagina,setListPagina]=useState([]);

  useEffect(()=>{
    if (nvoRegisPerfil.length!==0 &&!modalAvisoImg2 && !modalNvoImg ) {
      setTimeout(() => {
        //console.log('This will run after 1 second!')    
        setModalNvoImg(true);     
       //setModalAvisoImg(true)
      }, 500);  
    }
    
  },[nvoRegisPerfil])

  useEffect(()=>
  {           
    conectarJSon();    
    getTotal()   
    if (listPlantilla.length===0) 
    {
      getListPlantilla()     
    }     
        
    getListGeoJson()


    return ()=> {
      source.cancel();
    }                  
  },[actualizar])

  const conectarJSon = () => {
    const fInicio=moment(fSelect.inicio).format("YYYY-MM-DD");
    const fFinal=moment(fSelect.final).format("YYYY-MM-DD");             
    const data =  qs.stringify({        
          usuario:usuID,
          fini:fInicio+'T00:00:00',
          ffin:fFinal+'T23:59:59',
          status:0,        
          cadena:nombre!==""?nombre:"",            
          otro:"",            
          estado:edo!==""?edo:0,
          municipio:0,
          seccion:0,
          referente:referente,
          idDispositivo:auxDispV,
        })              
    //const url = encargado.length===0 || encargado?.UsrId===0?"perfil-lista-1-1" :"perfil-lista-encargado";
    const url ="perfil/v3/lista"
    function respuesta(aux) {
      if (aux[0].Id !== -1 &&aux[0].Id !== 0 ) 
      { guardarSinDatos(true)                                                           
        let auxPendiente=0, auxVerificado=0, auxRechazado=0
        let auxArrMuni=[],auxPos=[], arrM=[]
        arrM.push(["#","Municipio","Sección","Nombre","Teléfono","Género","Fecha Registro","Fecha Nacimiento","Foto"])
        aux.forEach((perfil,index) => {
          if (perfil.StatusId===1) 
          { auxPendiente=auxPendiente+1
          }
          if (perfil.StatusId===2) 
          { auxVerificado=auxVerificado+1
          }
          if (perfil.StatusId===3) 
          { auxRechazado=auxRechazado+1
          }
          let idColor= ordenIdColor(perfil.MunId )
          const auxMuni=perfil.MunNom?perfil.MunNom.charAt(0)+ perfil.MunNom.slice(1).toLowerCase() :""  
          let auxFReg=moment.utc( perfil.Fecha).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
          ? "" : moment.utc( perfil.Fecha).format('DD/MM/YYYY HH:mm')
          let auxNac=moment.utc( perfil.Nacimiento).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
          ? "" : moment.utc( perfil.Nacimiento).format('DD/MM/YYYY') 
          arrM.push([index+1,auxMuni,perfil.SeccNum,perfil.Nombre,perfil.Tel,perfil.Genero,auxFReg,auxNac,perfil.Imagen])            
          let filtrado=auxPos.findIndex((elemt)=>elemt===idColor)           
          if (filtrado ===-1) {
            ordenMapBx(auxArrMuni,idColor,colSecond) 
            auxPos.push(idColor)
          }
          
        });          
        setListaExcel(arrM)
        setTotalPerfil({
          Valido:auxVerificado, Pendiente:auxPendiente, Rechazado:auxRechazado})  
        setArregloColor(auxArrMuni)
        numeroPag(aux,tamMax,setTotalPaginas,setNumPagina,setListPagina)                     
        guardarDatos(aux)      
        
      }
      else
      { setListaExcel([])
        guardarDatos([])
        setListPagina([])
        guardarSinDatos(false)   
        setTotalPerfil([])    
        setArregloColor([])  
      }      
    }  
    llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
  };   

  const getTotal = () => {
    const fInicio=moment(fSelect.inicio).format("YYYY-MM-DD");
    const fFinal=moment(fSelect.final).format("YYYY-MM-DD");             
    const data =  qs.stringify({        
          usuario:usuID,
          fini:fInicio+'T00:00:00',
          ffin:fFinal+'T23:59:59',
          status:0,        
          cadena:nombre!==""?nombre:"",            
          otro:"",            
          estado:edo!==""?edo:0,
          municipio:0,
          seccion:0,
          referente:referente,
          idDispositivo:auxDispV,
        })                  
    const url ="perfil/v3/lista/total"
    function respuesta(aux) {
      if (aux.MunAvc !== -1 ) 
      {                                                
        
       setTotalSecc(aux)                 
      }
      else
      {   
        setTotalSecc([])                             
      }      
    }  
    llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
  };   

  const getListGeoJson = () => {
    const fInicio=moment(fSelect.inicio).format("YYYY-MM-DD");
    const fFinal=moment(fSelect.final).format("YYYY-MM-DD");             
    const data =  qs.stringify({        
          usuario:usuID,          
          status:0,                            
          estado:edo!==""?edo:0,
          municipio:0,
          seccion:0,
          otro:"",            
          cadena:nombre!==""?nombre:"",            
          fini:fInicio+'T00:00:00',
          ffin:fFinal+'T23:59:59',
          referente:referente,
          idDispositivo:auxDispV,
        })                  
    const url ="perfil/v3/geojson"
    function respuesta(aux) {
      if (aux[0].Id !== -1 &&aux[0].Id !== 0 ) 
      { let nvoEdo=[]
        aux.forEach((gjson)=>{
          nvoEdo.push(gjson)
        })
        setListGeoEdo(nvoEdo)
       
      }      
    }  
    llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
  };   

  function getListPlantilla()  {            //plantilla/v2/active
    const url= `plantilla/v2/active`;                            
    const data = qs.stringify({                
        usuario:usuID,          
       // dominio:process.env.REACT_APP_Dominio,                                         
        idDispositivo:auxDispV,
    });
                
    function respuesta (aux) 
    {  if (aux[0].Id !==  -1 ) 
      {  //console.log(aux);  
        setListPlantilla(aux)               
      }                                                                   
    }
    llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
  }; 

  function onChangePagina(event, newPage){
    event.preventDefault();
    if (newPage ===0) 
    {
      setListPagina(datos.slice(0,tamMax))   
    }
    else
    {
      setListPagina(datos.slice(newPage * tamMax, newPage * tamMax + tamMax))   
    }
    setNumPagina(newPage)
  }

  function generarFooter(){
    return(
    <TablePagination 
      rowsPerPageOptions={false}  style={{padding:0}}
      rowsPerPage={tamMax} count={datos.length}
      page={numPagina} lang='es'
      labelDisplayedRows={
          ({ from, to, count }) => {
            return '' + from + '-' + to + ' de ' + count
          }
        }
      onPageChange={onChangePagina}
      ActionsComponent={PaginacionTab}/>)
  }

  const exportarArch = () => {
    let today = new Date();                    
    let fec = moment(today);        
    //console.log(listaExcel);
    const ws = XLSX.utils.aoa_to_sheet(listaExcel);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Reporte");
    XLSX.writeFile(wb, fec.format("YYMMDDHHmmss")+" Lista General.xlsx")
  }; 

  const seleccionado=(auxId,auxOrden)=>{
    setSelectedID(auxId)   
    setRegisSelect(auxOrden)
  }      
  
  function tipoEdoProspecto(auxColorStd,etiqueta)
  {  
    let renglon=(
      <TableCell  align="center" padding={'none'}        
        style={{ color:'white',fontWeight:700 ,width:100+'px',paddingLeft:.4+'rem',paddingRight:.4+'rem'}} 
      >
        <Card style={{color:auxColorStd==="#EBF1F8"? "black":(auxColorStd==='#FFFFFF'?"black":'white'), 
        fontWeight:"bold", backgroundColor:auxColorStd}}>
          {etiqueta}
        </Card>
      </TableCell>    )
    return renglon
  } 

  function abrirModalMapa(auxSelec){
    let fecha = moment.utc(auxSelec.Fecha).format('YYYY-MM-DD HH:mm:ss') === "1900-01-01 00:00"
    ? "" : moment.utc(auxSelec.Fecha).format('DD/MM/YYYY HH:mm:ss')
    let titulo=`${auxSelec.Id}`;
    setTituloMapa(titulo)
    setSubtInfo(fecha)
    setRegisSelect(auxSelec)
    setInfoMapaMod(true)
  }
  
  function abrirModalInfo(auxSelec){
    setModalInfo(true)    
    let titulo=`Registro ${auxSelec.Id} `;
    setTituloMapa(titulo)
    setRegisSelect(auxSelec)
  }
  
  function abrirModalNvoPerfil(){    
    setModalNvoPerfil(true);    
  }
  
  function abrirModalEditPerfil(){      
    setModalEditPerfil(true);    
    }

  function sendImgPerfil(row){      
    EnviarPerfil({Id:row.Id}, toast,source,history)
  }
  function abrirModalNvoPerfilImg(){          
    setTimeout(() => {          
      setModalNvoImg(true);    
    }, 300);     
  }

  const celda=(alinear,estilo,contenido)=>{
    return (
    <TableCell align={alinear} className={classes.tableCell} padding={'none'} style={estilo} >
      {contenido}
    </TableCell>
    )
  }

  const celdaNvoPerfil=()=>{
    return(
    <Tooltip title={`Nuevo Perfil`}>
      <IconButton aria-label="agregar nuevo perfil" onClick={() => abrirModalNvoPerfil()} 
        component="span" size="small" 
      >
        <AddCircleOutlineIcon style={{color:"white" }} />  
      </IconButton>
    </Tooltip> 
    )
  }

  const editarPerfil=(e,row,)=>{
    e.preventDefault()
    setModalNvoImg(true);    
    const filtrado=listReferente.filter((refer)=> refer.Id===row.ReferId)   
    //console.log(filtrado);    
    setNvoRegisPerfil({Nom:`${row.Nombre} ${row.Paterno} ${row.Materno}`,llave:row.Id,PlantId:filtrado.length!==0 ? filtrado[0].PlantId:1})  
  }

  const formatoNom=(cadena)=>{
    return cadena.charAt(0)+ cadena.slice(1).toLowerCase()
  }

  const obtenerGen=(aux)=>{
    let respuesta=""
    switch (aux) {
      case 'H':
        respuesta="Hombre"
        break;
      case 'M':
        respuesta="Mujer"
        break;
      case 'X':
        respuesta="No Binario"
        break;
      case 'N':
        respuesta="Pendiente"
        break;
      default:
        break;
    }
    return respuesta
  }

  function Renglon(props)
  {
    const { row } = props; 
    const auxMuni=row.MunNom?formatoNom(row.MunNom) :""   
    const auxEdo=row.MunNom?formatoNom(row.EdoNom) :""   
    let auxNac=moment.utc( row.Nacimiento).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
    ? "" : moment.utc( row.Nacimiento).format('DD/MM/YYYY')    
    let auxFReg=moment.utc( row.Fecha).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
    ? "" : moment.utc( row.Fecha).format('DD/MM/YYYY HH:mm')
    let leyenda=`Crear Imagen`
    let tipoColor="inherit"
    let auxValida=1
    if(row.Imagen.length > 0)
    {
     // leyenda=`Imagen Cargada`
      tipoColor="primary"
      auxValida=0
    }
    
    let auxImg=null
    const imgEstilo=selectedID === row.Id ? {width:18+'px',color:'white',marginTop:.2+'rem'} :{width:18+'px',marginTop:.2+'rem'}
    auxImg = usuTipo === 1 ?
        (<Tooltip title={leyenda}> 
            <IconButton   aria-label="agregar imagen" size="small" 
              onClick={(e) => editarPerfil(e,row,auxValida)} 
              component="span">
              <ImageIcon style={imgEstilo} color={tipoColor} /> 
            </IconButton>
          </Tooltip> )
        : (<ImageIcon style={imgEstilo} color={tipoColor} />)                               
    const auxInfo= <Tooltip title={`Información de registro`}>
      <IconButton   aria-label="add circle" size="small" 
        onClick={() => abrirModalInfo(row)} component="span">
        {selectedID === row.Id ? 
          <InfoIcon style={{color:'white',width:18+'px'}} />
          :<InfoIcon style={{width:18+'px'}} color="primary"/>
        }
      </IconButton>                     
      </Tooltip>
    const auxMap=  <Tooltip title={`Ver Mapa`}>
      <IconButton   aria-label="add circle" size="small" 
        onClick={() => abrirModalMapa(row)} component="span">
          {selectedID === row.Id ? 
            <LocationOnIcon style={{color:'white',width:18+'px'}} />
            :<LocationOnIcon style={{width:18+'px'}} color="primary"/>
          }
        </IconButton>                            
      </Tooltip>                                                                                                                                              
    const auxEdit=  <Tooltip title={`Editar Perfil`}>
        <IconButton   aria-label="add circle" size="small" 
          onClick={() => abrirModalEditPerfil(row)} component="span">
            {selectedID === row.Id ? 
              <CreateIcon style={{color:'white',width:18+'px'}} />
              :<CreateIcon style={{width:18+'px'}} color="primary"/>
            }
          </IconButton>                            
        </Tooltip>                                  
    return (
    <React.Fragment>
    <TableRow onClick={ () => { seleccionado(row.Id,row); } }
      className={classes.tableRow} selected={ selectedID === row.Id}      
      classes={{ hover: classes.hover, selected: classes.selected }}        
    >
      {usuID === 1 ? celda("center",{width:10+'px'}, auxEdit):null}
      {celda("center",{width:10+'px'}, auxInfo)}
      {/* celda("center",{width:10+'px'}, auxMap) */}
      {celda("center",{width:10+'px',paddingLeft:.3+'rem'}, auxImg)}
      {usuID === 1 ?
      celda("center",{width:10+'px'}, 
      <Tooltip title={`Enviar Whats`}>
        <IconButton   aria-label="whats icon" size="small" 
          disabled //onClick={() => sendImgPerfil(row)} 
          component="span">            
            <WhatsAppIcon style={{width:18+'px'}} color="primary"/>            
          </IconButton>                            
        </Tooltip>    ) 
        :null}
      {tipoEdoProspecto(row.IneStatColor ,row.IneStatNom  )}         
      {celda("center",{width:105+'px',fontSize:12+'px',paddingLeft:8+'px'}, auxFReg)}
      {celda("center",{width:220+'px',fontSize:12+'px', paddingRight:2+'px'}, auxEdo) }
      {celda("center",{width:220+'px',fontSize:12+'px', paddingRight:2+'px'}, auxMuni) }      
      {celda("center",{fontWeight:700,width:40+'px', paddingRight:2+'px'}, row.SeccNum)}        
      {celda("center",{width:50+'px',fontSize:12+'px',paddingLeft:8+'px'}, row.Tel)}
      {celda("left",{width:50+'px',fontSize:12+'px',paddingLeft:8+'px'}, row.UserNom)}  
      {celda("left",{width:220+'px',fontSize:12+'px',paddingLeft:8+'px'}, `${row.Nombre} ${row.Paterno} ${row.Materno}`)} 
      {celda("left",{width:150+'px',fontSize:12+'px',paddingLeft:8+'px'}, row.ReferNom)} 
      {celda("center",{width:70+'px',fontSize:10+'px',paddingLeft:8+'px'},obtenerGen( row.Genero))}                                                                                               
      {celda("center",{width:105+'px',fontSize:12+'px',paddingLeft:8+'px'}, auxNac)}                                                                                                                                                                                   
    </TableRow>        
    </React.Fragment>
  );}
  
  const vacia=<TableCell  className={classes.tableCell} align="center"> </TableCell>
  const ColumnaVacia=<TableCell  className={classes.tableCell} align="center" style={{ backgroundColor:fondo,color:'white'}}  > </TableCell>

  function tablaCompleta(auxlista){ 
    return(
    <TableContainer className={classes.container}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>  
          {usuID === 1 ?ColumnaVacia:null}
          {usuID === 1 ?ColumnaVacia:null}
        {/* usuTipo === 1 ?<TableCell align="center" padding={'none'} style={{ backgroundColor:fondo,color:'white'}}  >     
         <IconButton onClick={exportarArch} size="small" >
            <Tooltip title="Exportar a Excel">
              <SaveIcon style={{color:'white'}} size="small" />
            </Tooltip>
          </IconButton>                                  
          </TableCell>
            :ColumnaVacia */}
            {/* ColumnaVacia */}
          <TableCell align="center" padding={'none'} style={{ backgroundColor:fondo,color:'white'}}  >     
            {celdaNvoPerfil() /*  */ }                                  
          </TableCell>   
          <TableCell align="center" padding={'none'} style={{ backgroundColor:fondo,color:'white'}}  >                          
            {auxlista.length}
          </TableCell>                           
          {columnas.map(row => {              
            return(  
            <TableCell style={{ backgroundColor:fondo,color:'white'}} 
              key={row.titulo+row.id}    align={row.alinear} padding={'none'} 
            >
              {row.titulo}
            </TableCell>    
            )                
          })}           
        </TableRow>
      </TableHead>
      <TableBody>
        {auxlista.length!=0 ? (auxlista.map((row) => (
            <Renglon key={"regPerfilGen"+row.Id} row={row} />
        ))):null}
      </TableBody>
    </Table>
    </TableContainer>
    )
  }
    
  const tablaSinDatos=()=>{
    return(
    <TableContainer className={classes.container2}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>            
          <TableCell align="center" padding={'none'}  style={{ backgroundColor:fondo,color:'white'}} >              
            {celdaNvoPerfil()  /* */}           
          </TableCell>       
          {columnas.map(row => {              
            return(  
            <TableCell style={{ backgroundColor:fondo,color:'white'}} 
              key={row.titulo+row.id}    align={row.alinear} padding={'none'} 
            >
              {row.titulo}
            </TableCell>    
            )                
          })}                                                
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow  className={classes.tableRow} > 
          {vacia}
          {vacia}  
          {vacia}                
          <TableCell  className={classes.tableCell} align="center">Sin </TableCell>  
          {vacia}               
          <TableCell  className={classes.tableCell} align="center">Registros</TableCell>
          {vacia}      
          {vacia}     
          {vacia}             
        </TableRow>
      </TableBody>
    </Table>
    </TableContainer>
  )}
     
  const contenido = sinDatos&&listPagina.length!==0 ?tablaCompleta(listPagina):tablaSinDatos()
  const tabla = espera ?  (<Box pt={3}><LinearProgress/> </Box>) :( contenido )
 
  const modalMap = infoMapaMod ? <ModalMapa seleccionado={regisSelect} titulo={regisSelect.Nombre ? "Promovido "+regisSelect.Nombre:"Promovido"} subtitulo={subtInfo}
                                    setModalAbierto={setInfoMapaMod } modalAbierto={infoMapaMod}/> : null;     

  const modInfo = modalInfo ? <InfoPromo seleccionado={regisSelect} modalAbierto={modalInfo} 
                                setModalAbierto={setModalInfo} titulo={regisSelect.Nombre ? "Promovido "+regisSelect.Nombre:"Promovido"} /> : null;            
                                
  const modEditPerf = modalEditPerfil && listPlantilla.length!== 0 ? 
                                  <ModalEditPerfil modalAbierto={modalEditPerfil} setModalAbierto={setModalEditPerfil} 
                                    titulo="Editar Promovido" perfil={regisSelect} listPerfil={datos}
                                    listMun={[]} listSecc={[]}
                                  />:null

  const modNvoPerf = modalNvoPerfil && listPlantilla.length!== 0 ? 
                                  <ModalPerfilv2 modalAbierto={modalNvoPerfil} setModalAbierto={setModalNvoPerfil} 
                                    titulo="Nuevo Promovido"  listPlantilla={listPlantilla} listReferente={listReferente}
                                    setModalAvisoImg={setModalAvisoImg2} setNvoRegisPerfil={setNvoRegisPerfil}
                                  />:null
  const modNvoImg =modalNvoImg  && listPlantilla.length!== 0  ? 
                                  <ModalNvoPerfilImg modalAbierto={modalNvoImg} setModalAbierto={setModalNvoImg} 
                                    titulo= {nvoRegisPerfil.Nom ? "Promovido "+nvoRegisPerfil.Nom:"Promovido"}  auxPerfImg={listPlantilla}
                                    nvoRegisPerfil={nvoRegisPerfil} actualizar={actualizar} setActualizar={setActualizar}
                                  />:null         
                                     
  const modNvoAviso = modalAvisoImg  ? <ModalAvisoPerfil modalAbierto={modalAvisoImg} setModalAbierto={setModalAvisoImg} 
                                        titulo="Validación" abrirModImg={abrirModalNvoPerfilImg} 
                                        actualizar={actualizar} setActualizar={setActualizar}
                                      />:null        
  const paginacion = !espera&&listPagina.length!==0 &&totalPaginas>1 ? generarFooter() : null;                                                                                                                                                                   

  return (
  <div style={{minWidth:55+'rem',maxWidth:65+'rem'}}>        
 {/* usuID&&parseInt(usuID)===1? <button onClick={()=>setModalNvoImg(true)}>prueba</button>:null */}
    {paginacion}      
    {tabla}           
    {/* modalMap */}
    {modInfo}
    {modNvoPerf}
    {modNvoImg}
    {modNvoAviso}
    {modEditPerf}
  </div>
  )
}

export default TablaPerfilGeneral