import React,{useState, useEffect} from 'react';
import TablaGeneral from './TablaGeneral'
import {useHistory} from "react-router-dom";
import {Box,IconButton,Tooltip, Select,Typography,
  Card,TextField, LinearProgress, FormControl, InputLabel, MenuItem} from "@material-ui/core";
import IntervaloSelect from './IntervaloTabla';
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from '@material-ui/icons/Search';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CardPerfil  from './CardPerfil';
import CardTotal from './CardTotal';
//import ContenedorMapa from './ContenedorMapa';
import MapDelegacion from '../MapaEdo';
import axios from "axios";
import qs from "qs";
import {ErrorGeneral, llamaApiCargaToken, llamadaApiToken} from "../funciones/ClienteAxios";
import SelectEdo from '../generales/SelectEdo';
import { obtenDispo } from '../funciones/IdDispositivo';
import {SelectList} from '../generales/Inputs';
import MapaGPS from '../MapGeneral';
import moment from 'moment';
const arrBase={
  "Id": 0, "MunId": 0, "MunNom": "TODOS",
  "MunNum": 0, "UsrId": 0, "UsrNom": "",
  "UsrTel": "0","UsrUsr":""
}
const GeneralInicio = () => {
  let history = useHistory();
  const idusu = localStorage.getItem("UsuId") ? parseInt(localStorage.getItem("UsuId")):0;
  const usuTitu = localStorage.getItem("usuTitu")?parseInt(localStorage.getItem("usuTitu")) :0
  const source = axios.CancelToken.source();
  const [fSelect, setFSelect] = useState({inicio:new Date(),final:new Date()});
  const auxDispV =obtenDispo()
  //const[totalPerfil,setTotalPerfil]= useState({Valido:0,Pendiente:0,Rechazado:0});
  const[totalPerfil,setTotalPerfil]= useState([]);
  const[totFiltro,setTotFiltro]= useState([]);
  const[actualizar,setActualizar]=useState(true);
  const [arregloColor,setArregloColor] = useState([])  
  const [listMun,setListMun]=useState([])
  const [munSvgSelect,setMunSvgSelect]=useState(0)  
  const [listEncargado,setListEncargado]=useState([])
  const [cargaList,setCargaList]=useState([])
  const [encargado,setEncargado]=useState([])
  const[nombre, setNombre]=useState("")
  const[filtro,setFiltro]=useState({edo:"",TipoUsu:"",muni:"", geoEdo:"", geoMun:"", geoSecc:"", referente:0})
  const [listGeoJson,setListGeoJson]=useState([]);
  const [listGeoEdo,setListGeoEdo]=useState([]);
  const [listGeoMun,setListGeoMun]=useState([]);
  const [listGeoSecc,setListGeoSecc]=useState([]);
  const [listGpsSecc,setListGpsSecc]=useState([]);
  const [mapaGeOJson,setMapGeoJson]=useState("");
  const[listReferente, setListReferente] = useState([])  
  const[totalSecc, setTotalSecc] = useState([])  
  const{edo,TipoUsu,muni, geoEdo, geoMun, geoSecc, referente}=filtro

  useEffect(() => {    
    if(usuTitu===1){
      llenalistEncarga()
      
      getListReferente()
    }
    return () => {
      source.cancel();
    }
  }, [])

  useEffect(() => {    
    if(geoSecc!==""){
      getlistGPSSecc(geoMun,geoSecc)
    }    
    
  }, [geoSecc])
  
  useEffect(() => {    
    if(geoMun!==""){
      getlistGPSSecc(geoMun,0)
    }    
    
  }, [geoMun])

  const getListReferente = () => {
    const data = qs.stringify({
        usuario: idusu, idDispositivo: auxDispV,
    });
    const url = "usuario/v3/estructura/referente";

    function respuesta(auxiliar) {
      if (auxiliar[0].Id !== -1) {
        
        return setListReferente(auxiliar)
      }
    }

    llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargaList, history, source);
  }

  const llenalistEncarga = () => {
    const data = qs.stringify({        
        Usuario: idusu,
        idDispositivo: auxDispV,
    });
    const url = "coordinador-municipal-encargado-list";

    function respuesta(aux) {
      if (aux[0].Id!==-1 ) 
      {
        const nvoList=[arrBase, ...aux ]
        setListEncargado(nvoList)
      } 
      else 
      {  
        setListEncargado([])
      }
    }

    llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargaList, history, source);
  }; 

  const getlistGPSSecc = (geoMun,geoSecc) => {
    const fInicio=moment(fSelect.inicio).format("YYYY-MM-DD");
    const fFinal=moment(fSelect.final).format("YYYY-MM-DD");   
    //console.log(geoMun,geoSecc);
    const data = qs.stringify({                
        usuario:idusu,
        status:0,
        estado:geoEdo,
        municipio:geoMun,
        seccion:geoSecc,
        otro:"",
        cadena:"",
        fini:`${fInicio}T00:00:00`,
        ffin:`${fFinal}T23:59:59`,
        idDispositivo: auxDispV,
    });
    const url = "perfil/v2/gps/muni-secc";

    function respuesta(aux) {
      if (aux[0].Id!==-1 ) 
      {    //console.log(aux);    
        setListGpsSecc(aux)
      } 
      else 
      {  
        setListGpsSecc([])
      }
    }

    llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargaList, history, source);
  };

  function getListMuni(auxEdo)  {            
    const url= `lugar-municipio`;                            
    const data = qs.stringify({                
        usuario:idusu,              
        estado:auxEdo,                                                
        idDispositivo:auxDispV,
    });
                
    function respuesta (aux) 
    {   if (aux[0].Id != -1) 
        {   setListMun(aux)
        }  
        else{
            setListMun([])
        }                                                                    
    }
    llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
  };

  const generarLista = e =>{   
    e.preventDefault()    
    setTotalPerfil([])
    setArregloColor([])
    setActualizar(!actualizar)    
    setMapGeoJson("")
    setListGeoMun([])      
    setListGeoSecc([])      
    setFiltro({...filtro,geoEdo:'',
      geoMun:"", geoSecc:'',})
    setListGpsSecc([])
    setTotFiltro([])
  }

  const letraTipo=(auxNvl,auxNvlId,auxUser)=>{
    //console.log(auxNvl);
    return(
    <span   >
      <Typography color="primary" variant="body2" id={auxUser+auxNvlId} 
        style={{fontSize:.8+'rem',fontWeight:600}}
      >
        {auxUser}
      </Typography>
      <Typography color="textPrimary" variant="body2" id={auxNvl+auxNvlId} 
        style={{fontSize:.7+'rem',textAlign:"center"}}
      >
        {auxNvl}
      </Typography>
    </span>
    )
  }
    
  
  const generaCard=(seleccionado)=>{
    let auxMun=listMun.filter((muni)=>muni.Id===seleccionado)
    //console.log(auxMun);
    return(
    <Card style={{padding:.3+'rem'}}>
      <Typography>{auxMun.length > 0 ? auxMun[0].Nom : ""}</Typography>
    </Card>
    )
  }

  const guardarMuni=(e)=>{
    //console.log(e);
    if (e!== null && e!== undefined) 
    { setEncargado(e)            
    }
    else
    { setEncargado([])     
    }
 
  }

  const filtroSlct=(e)=>{
    let arr=[], filtrado=[]
    switch (e.target.name) {                
      case 'geoEdo':
        filtrado=listGeoEdo.filter((estado)=>estado.Id === e.target.value)        
        arr={...filtro,geoEdo:e.target.value,
          geoMun:"", geoSecc:'',}        
        //console.log(filtrado[0].municipio);
        setMapGeoJson(filtrado[0].Geo??"")
        setListGeoMun(filtrado[0].municipio ?? [])      
        setListGeoSecc([])    
        setListGpsSecc([]) 
        setTotFiltro({Valido:filtrado[0].Vali, Pendiente:filtrado[0].Pend, Rechazado:filtrado[0].Rech})       
        break
      case 'geoMun':
        filtrado=listGeoMun.filter((municipio)=>municipio.Id === e.target.value)        
        arr={...filtro,geoMun:e.target.value, geoSecc:''}
        setListGeoSecc(filtrado[0].seccion ?? [])    
        setListGpsSecc([])
        setTotFiltro({Valido:filtrado[0].Vali, Pendiente:filtrado[0].Pend, Rechazado:filtrado[0].Rech})       
        break
      case 'geoSecc':
        filtrado=listGeoSecc.filter((seccion)=>seccion.Id === e.target.value)        
        arr= {...filtro,[e.target.name]:e.target.value}
        setTotFiltro({Valido:filtrado[0].Vali, Pendiente:filtrado[0].Pend, Rechazado:filtrado[0].Rech})       
        break
      default:
        arr= {...filtro,[e.target.name]:e.target.value}
        break;
    }
    //setMensaje('');                
    //setAviso(false);   
    setFiltro(arr)
  }

  const onChange = e => {
    setNombre( e.target.value)
  }

  const onChangeSlct=(e)=>{    
    if (e!== null && e!== undefined) 
    {            
      filtroSlct(e)
    }
    else
    {   setFiltro({...filtro,[e.target.name]:""})   
   
    }        
  }
  const slctReferente =                        
    <FormControl  style={{minWidth:10+'rem',marginLeft:".5rem"}} >
    <InputLabel id={`referenteId`}>{`Referente`}</InputLabel>
    <Select  label={`Referente`} id={`Referente-Slct`} name={`referente`}
      value={referente} onChange={onChangeSlct}
    >
    {listReferente.map((lugar) => {
      const auxTitulo=lugar.Id ===0 ? "TODOS":lugar.Descr
      return(
      <MenuItem value={lugar[`Id`]} key={lugar[`Id`]}>
          <em>{auxTitulo}</em>
      </MenuItem>
      )
    } )}     
    </Select>
</FormControl>  

  const slctGeoEdo =                        
  <SelectList lista={listGeoEdo} value={geoEdo} onChange={onChangeSlct} 
      etqVal={"Id"} etqId="Id" etqAlias={"Nom"} etiq={"Estado"} nameEtq={"geoEdo"}
      estilo={{marginLeft:'1rem',width:'13.5rem'}} 
  />   

  const slctGeoMun =                        
  <SelectList lista={listGeoMun} value={geoMun} onChange={onChangeSlct} 
      etqVal={"Id"} etqId="Id" etqAlias={"Nom"} etiq={"Municipio"} nameEtq={"geoMun"}
      estilo={{marginLeft:'1rem',width:'14.5rem'}} 
  />  

  const slctGeoSecc =                        
  <SelectList lista={listGeoSecc} value={geoSecc} onChange={onChangeSlct} 
      etqVal={"Id"} etqId="Id" etqAlias={"Nom"} etiq={"Sección"} nameEtq={"geoSecc"}
      estilo={{marginLeft:'1rem',width:'14.5rem'}} 
  />  

  //const cardMuni= listMun.length!==0 && munSvgSelect!== 0 ? generaCard(munSvgSelect):null
  return(
  <div>
    <Box display={'flex'} pb={1}>
      <Box >
        <IconButton color="primary"  aria-label="add circle" 
          component="span" onClick={generarLista}>
            <Tooltip title="Buscar">
            <SearchIcon/>
            </Tooltip>
        </IconButton>                                                                                                                              
      </Box>    
      {idusu === 1 ?
          <Box pl={1} pt={1}>
            <TextField id="nombre-txt" name="nombre" label="Nombre" fullWidth size="small"
              value={nombre} onChange={onChange} variant="outlined"/>  
          </Box>
        :null }
      <Box display={"flex"}>
        <IntervaloSelect fSelect={fSelect}  setFSelect={setFSelect} />
        <Box pl={1} pt={1}>
          { usuTitu ===1 && listEncargado.length!==0 ?
            <Autocomplete
              id="encargados-autocomplete-01" size='small'
              onChange={(e,option)=>guardarMuni(option)}
              options={listEncargado}
              getOptionLabel={(option) => `${option.MunNom} - ${option.UsrNom} ${option.UsrUsr!==""?"("+option.UsrUsr+")":""}`}
              style={{ width: 40+'rem' }}
              noOptionsText={"Sin coincidencias"}
              renderInput={(params) => (
                  <TextField {...params} 
                  label="Encargados" variant="outlined" />
              )}
            />
          :<LinearProgress/>}
        </Box>          
       {/*  <Box ml={1} pb={1} >
          <SelectEdo edo={edo} onChangeSlct={onChangeSlct} />
        </Box>   */}       
        {listReferente.length!==0 ? slctReferente:null}
      </Box>
    </Box>   

    <Box display={"flex"}>
      {totalPerfil.length !== 0 ? 
        <CardPerfil valor={totalPerfil} />
      :null}    
      {totalSecc.length !== 0 ? 
        <Box ml={2}>
          <CardTotal valor={totalSecc}/>
        </Box>          
      :null}
    </Box>
    <Box display={"flex"} row  width={"100%"} >
      <Box width={"71%"} style={{minWidth:63+'rem'}} >
      <TablaGeneral fSelect={fSelect} actualizar={actualizar} setActualizar={setActualizar}
        setTotalPerfil={setTotalPerfil} setArregloColor={setArregloColor}
        setListMun={setListMun} listMun={listMun} listReferente={listReferente}
        nombre={nombre} edo={edo} setListGeoEdo={setListGeoEdo}
        referente={referente} setTotalSecc={setTotalSecc}
      />
      </Box>
      {arregloColor.length !== 0 ?
      <Box display={"flex"} flexDirection="column"  style={{minWidth:20+'rem'}} >
        {/* <span  >
          {cardMuni}
          <ContenedorMapa coloreados={arregloColor} setMunSvgSelect={setMunSvgSelect}/>
        </span> */}
        <Box display={"flex"}>
          {listGeoEdo.length!==0? slctGeoEdo:null}
          { listGeoMun.length!==0? slctGeoMun:null }            
        </Box>
        
        <Box mt={1} mb={1}>
        { listGeoSecc.length!==0? slctGeoSecc:null }
        </Box>
        {totFiltro.length !== 0 ? 
          <Box ml={2}>
            <CardPerfil valor={totFiltro} />
          </Box>          
        :null}
   
        
        {mapaGeOJson!=="" && listGpsSecc.length===0?<span style={{marginLeft:1+'rem'}} >
          <MapDelegacion arregloColor={arregloColor} mapaGeOJson={mapaGeOJson}/> 
        </span>:null}
        {listGpsSecc.length!==0 ? 
          <MapaGPS muniGps={listGpsSecc} zoom={13}
            tipo={1} coordPerfiles={[]} auxMapVar={[]} />
        :null}
      </Box>
      :null
      }
    </Box>
  </div>
  );
};

export default GeneralInicio;