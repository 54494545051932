import React,{useState,useEffect} from 'react';
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow
        ,Card,TextField,Box, Paper,Tooltip,IconButton, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    estiloCard:{
        width: '31rem',
        boxShadow:'0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%)',
       // color:'#4a4a4a',
        display:'block',
        paddingBottom:'.5rem',
        paddingLeft:'.5rem',
        paddingRight:'.5rem',
        maxHeight:'100%',
        minHeight: "11rem",
        marginRight:'1rem'
    },
    formControl:{
        boxSizing:'border-box',
        fontSize:'1rem',
        position:'relative',
        marginBottom:'1em'
    },
    container: {
      width: '30rem',
      minHeight: "39rem",
      //minWidth:'100%',
    },
    tablaContainer: {
        width: '30rem',
        maxHeight: "10rem",
        minHeight: "8rem",
        //minWidth:'100%',
      },
    totalEstilo:{
        fontSize:'1.25rem',
        fontWeight:'bold'
    },
    tituloEstilo:{
        paddingBottom:'.25rem',
        fontWeight:'bold'
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor:process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
  const columnas=[                    
    { id:1, titulo:"Usuario", alinear:'center' },      
    { id:2, titulo:"Telefono", alinear:'center' },      
   /*  { id:3, titulo:"Total", alinear:'center' },  */     
    { id:4, titulo:"Puentes", alinear:'center' },          
    /* { id:5, titulo:"Barda", alinear:'center' },          
    { id:6, titulo:"Esp", alinear:'center' },          
    { id:7, titulo:"Otros", alinear:'center' },               */
    
  ];

const alturaCont = window.innerHeight<937 ? window.innerHeight-120 : window.innerHeight-160

export default function TablaTop({listTop }){    
  const classes = useStyles();    
  const [promotor, setPromotor] = useState({nombre:""})
  const [registro, setRegistro] = useState(null)
  const [datos,setDatos] = useState([]);     

  const auxCol=process.env.REACT_APP_Fondo_Color
  const {nombre} = promotor;    
  useEffect(()=>{
//console.log(listTop);
    setDatos(listTop)

    //la siguiente linea puede generar errores o no en el useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[listTop])          

  const onChangeTable =(regis)=>{                       
    setRegistro(regis)
  }

  function filtro(){        
    let auxCadena=nombre.trim();       
    let info=[];                        
    let aux=  datos.filter((dat)=>dat.nombreReg.toUpperCase().search(auxCadena.toUpperCase() ) !== -1)         
    info=aux 
    let auxNum=0;
      if (aux!==null||aux!=='') 
      {
      aux.forEach((mun)=>
      { if (mun.nombreReg!=='PUEBLA')
        {   
          auxNum++;
        }                
      })
    }         
    return TablaEstr(info);
  }


  const celda = (alinear, estilo, contenido,index) => {
    return (
    <TableCell align={alinear} key={index} className={classes.tableCell} 
        padding={'none'} style={estilo} >
        {contenido}
    </TableCell>)
  }

  const CelTitl=({index,titulo,aline})=> celda(aline?aline:"center", {backgroundColor:auxCol,color:'white',}, titulo?titulo:"",index) 

  function TablaEstr(auxDatos) {        
    
    return(        
    <TableContainer className={classes.tablaContainer} id="tamTablaReg"  >
      <Table stickyHeader aria-label="sticky table">
        <TableHead >    
          <TableRow>  
        
            {columnas.map((columna,index)=>              
              <CelTitl index={index+5} titulo={columna.titulo} aline={columna.alinear} />                          
            )}                                       
          </TableRow>                        
        </TableHead>  
        <TableBody>         
        {auxDatos.map((elemnto,index) => {  
          const auxVal=registro?.Tel === elemnto?.Tel ?true:false                                              
          return (                  
          <TableRow className={classes.tableRow} selected={ auxVal} 
            classes={{ hover: classes.hover, selected: classes.selected }} 
            key={index+1} onClick={ () => onChangeTable(elemnto) }  
          >            
            {celda("left", {width:"8rem",fontSize:"12px"}, elemnto.Usr,index+1) }
            {celda("center", {width:"2rem",fontSize:"11px"}, elemnto.Tel,index+2) }    
            {/* celda("right", {width:"2rem",fontSize:"12px"}, elemnto.Total,index+3)  */}    
            {celda("right", {width:"2rem"}, elemnto.Puente,index+4) }    
{/*             {celda("right", {width:"2rem"}, elemnto.Barda,index+5) }
            {celda("right", {width:"2rem"}, elemnto.Esp,index+6) }   
            {celda("right", {width:"2rem",paddingRight:".3rem"}, elemnto.Otro,index+7) }        */}                                                                                           
          </TableRow>              
          );                        
          })
        }                                                
        </TableBody>
      </Table>
    </TableContainer>        
    ); 
  }
                   
  
   const llamada2= datos.length !== 0 &&datos.topMin ?(TablaEstr(datos.topMin)):(<Box style={{paddingTop:1+'rem'}}>
                                  <Typography variant="h5" component="h5">No contiene datos</Typography>
                                  </Box>)
 
    const llamada=  datos.length !== 0 && datos.topMax ?(TablaEstr(datos.topMax)):(<Box style={{paddingTop:1+'rem'}}>
                                    <Typography variant="h5" component="h5">No contiene datos</Typography>
                                    </Box>) 
  return (
  <Box display={'flex'} flexDirection="row">
    <Box display={'flex'} flexDirection="column" style={{height: "12rem"}}>
      
      <Card className={classes.estiloCard} style={{height:"12rem"}}>                                  
      <Typography>Mejor Rendimiento</Typography>
        {  llamada }   
      </Card>
    </Box> 
    <Box display={'flex'} flexDirection="row" style={{height: "12rem"}}>
      <Card className={classes.estiloCard} style={{height:"12rem"}}>    
      <Typography>Menor Rendimiento</Typography>                              
        { llamada2 }   
      </Card>
    </Box> 
  </Box>                   
  );
}