import {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    Box,
    Collapse,
    IconButton,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from '@material-ui/core';
import qs from 'qs';
import moment from 'moment';
import {ErrorGeneral, llamadaApiToken} from '../funciones/ClienteAxios';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ModalMapa from "./ModalMapa";
import CreateIcon from '@material-ui/icons/Create';
const useStyles = makeStyles(theme=>({
    container: {
        maxHeight: window.innerHeight - 170,
        minHeight: 280,
        minWidth: '100%',
    },
    container2: {
        maxHeight: 550,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: theme.palette.secondary.main
        }
    },
    tableCell: {
        "$selected &": {
            color: "white"
        }
    },
    hover: {},
    selected: {}
}));

export default function TablaComercios({ history, fechasSeleccionadas, selectedID, setSelectedID, source,
                                            idusu, buscar, checked, abrirModal, setAbrirModal, setRecorrido
                                        }) {
    const classes = useStyles();
    const [espera, setEspera] = useState(false);
    const [datos, guardarDatos] = useState([])
    const [sinDatos, guardarSinDatos] = useState(true)
    const [open, setOpen] = useState(false);
    const [seleccionado, setSeleccionado] = useState([]);
    let fInicio = moment(fechasSeleccionadas.inicio).format("YYYY-MM-DD")
    let fFinal = moment(fechasSeleccionadas.final).format("YYYY-MM-DD")
    let filter = 0


    useEffect(() => {
        const llenarTabla = () => {
            setEspera(true)
            if (checked) {
                filter = idusu
            } else {
                filter = 0
            }

            const data = qs.stringify({
                Usuario: idusu,                
                Fini: fInicio + 'T00:00:00',
                Ffin: fFinal + 'T23:59:59',
                idDispositivo: '45vf2d1vdf21d2f'
            });
            const url = "comercio/v1/list";

            function response(data) {
                if (data[0].Id !== -1 && data[0].Id !== 0) {
                    guardarSinDatos(true)
                    guardarDatos(data)
                    setEspera(false)
                } else {
                    guardarSinDatos(false)
                    setEspera(false)
                }
            }

            llamadaApiToken(data, url, response, ErrorGeneral, history, source);
        };

        llenarTabla();
    }, [buscar]);

    const seleccionar = (auxId, auxOrden) => {
        setSelectedID(auxId)
        setRecorrido(auxOrden)
        setSeleccionado(auxOrden)
    }

    const abrirModalMapa = () => {
        setAbrirModal(true)
    }

    const modalMapa = abrirModal ? <ModalMapa abrirModal={abrirModal}
                                        setAbrirModal={setAbrirModal}  datos={seleccionado}
                                    /> : null

    function verMas(auxId) {
        setSelectedID(auxId)
        if (auxId === selectedID) {
            setOpen(!open)
        } else {
            setOpen(false)
        }
    }

    function obtnDir(comercio) {
        let respuesta=""
        if (comercio.Col!=="") {
            respuesta=`Col. ${comercio.Col}`
        }
        if (comercio.Calle!=="") {
            respuesta=`${respuesta}, Calle ${comercio.Calle}`
        }
        if (comercio.NoExt!=="") {
            respuesta=`${respuesta} #${comercio.NoExt}`
        }
        if (comercio.NoInt!=="") {
            respuesta=`${respuesta} INT ${comercio.NoInt}`
        }
        if (comercio.Cp!=="" && comercio.Cp!=="00000") {
            respuesta=`${respuesta}, ${comercio.Cp}`
        }
        return respuesta

    }

    function Row(props) {
        const {row} = props;
        return (
        <>
            <TableRow onClick={() => { seleccionar(row.Id, row) }}
                className={classes.tableRow} selected={selectedID === row.Id}
                classes={{hover: classes.hover, selected: classes.selected}}
            >                                       
                {/* <TableCell align="center" padding={'none'} style={{width: 30}}>
                    {row.Obsrv.length !== 0 ?
                        <Tooltip title={open && selectedID === row.Id ? 'Ver menos' : 'Ver más'}>
                            <IconButton color="primary" aria-label="expand row" size="small"
                                onClick={() => verMas(row.Id)}>
                                {open && selectedID === row.Id ?
                                    (selectedID === row.Id ?
                                        <KeyboardArrowUpIcon style={{color: 'white'}}/>
                                        : <KeyboardArrowUpIcon/>)
                                    : (selectedID === row.Id ?
                                        <KeyboardArrowDownIcon style={{color: 'white'}}/>
                                        : <KeyboardArrowDownIcon/>)}
                            </IconButton>
                        </Tooltip>
                        : null}
                </TableCell> */}
                {row.Lat && row.Lon !== 0.0 ?
                    <TableCell align="center" padding={'none'} style={{width: 30}}>
                        <Tooltip title={'Ver más'}>
                            <IconButton color="primary" size="small"
                                onClick={() => abrirModalMapa()}>
                                {selectedID === row.Id ?
                                    <LocationOnIcon style={{color: 'white'}}/> 
                                    : <LocationOnIcon/>}
                            </IconButton>
                        </Tooltip>
                    </TableCell> 
                    : <TableCell align="center" padding={'none'} style={{width: 30}}></TableCell>
                }

                <TableCell className={classes.tableCell} align="center" padding={'none'} width={75}
                    style={{fontSize: 12 + 'px'}} >
                    {moment.utc(row.FReg).local(true).format("DD/MM/YYYY HH:mm")}
                </TableCell>
                {/* <TableCell className={classes.tableCell} align="center" padding={'none'} width={75}
                    style={{fontSize: 12 + 'px'}} >
                    {moment.utc(row.FEnv).local(true).format("DD/MM/YYYY HH:mm")}
                </TableCell> */}
                <TableCell className={classes.tableCell} align="center" padding={'none'} width={180}
                    style={{fontSize: 12 + 'px'}} >
                    {row.telefono}
                </TableCell>
                <TableCell className={classes.tableCell} align="left" padding={'none'} width={180}
                    style={{fontSize: 12 + 'px'}} >
                    {row.email}
                </TableCell>
                <TableCell className={classes.tableCell} align="left" padding={'none'} width={180}
                    style={{fontSize: 12 + 'px'}} >
                    {row.Nombre}
                </TableCell>
                <TableCell className={classes.tableCell} align="center" padding={'none'} width={120} >
                    {row.LicenciaNom}
                </TableCell>
                <TableCell className={classes.tableCell} align="center" padding={'none'} width={80}
                    style={{fontSize: 12 + 'px'}} >
                    {row.InverNom}
                </TableCell>
            
                <TableCell className={classes.tableCell} align="center" padding={'none'} width={55} style={{fontSize: 12 + 'px'}}>
                    {row.SectorNom}
                </TableCell>
                <TableCell className={classes.tableCell} align="center" padding={'none'} width={55} style={{fontSize: 12 + 'px'}}>
                    {row.persona}
                </TableCell>
                <TableCell className={classes.tableCell} align="left" padding={'none'} style={{fontSize: 12 + 'px'}}
                     width={220}>
                    {obtnDir(row)}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={9}>
                    <Collapse in={open && selectedID === row.Id && row.Obsrv.length !== 0} timeout="auto"
                        unmountOnExit>
                        <Box margin={1}>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                    <TableCell padding={'none'}> Observación </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow key={row.Id}>
                                    <TableCell padding={'none'}> {row.Obsrv}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
        );
    }

    function tablaCompleta(auxlista) {
        return (
        <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>
                    {/* <TableCell align="center" padding='none'></TableCell> */}
                    <TableCell align="center" padding='none'>{auxlista.length}</TableCell>                            
                    <TableCell align="center" padding='none'>Fecha Sistema</TableCell>
                    
                    <TableCell align="center" padding='none'>Teléfono</TableCell>
                    <TableCell align="center" padding='none'>Email</TableCell>
                    <TableCell align="center" padding='none'>Nombre</TableCell>
                    <TableCell align="center" padding='none'>Licencia</TableCell>
                    <TableCell align="center" padding='none'>Inversion</TableCell>                    
                    <TableCell align="center" padding='none'>Sector</TableCell>
                    <TableCell align="center" padding='none'>Tipo Persona</TableCell>
                    <TableCell align="center" padding='none'>Dirección</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {auxlista.length != 0 ? (auxlista.map((row) => (
                    <Row key={row.Id} row={row}/>
                ))) : null}
            </TableBody>
        </Table>
        </TableContainer>
        )
    }

    const tablaSinDatos = () => {
        return (
            <TableContainer className={classes.container2}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" padding='none'></TableCell>
                            <TableCell align="center" padding='none'>Fecha Sistema</TableCell>
                            <TableCell align="center" padding='none'>Fecha Registro</TableCell>
                            <TableCell align="center" padding='none'>Nombre</TableCell>
                            <TableCell align="center" padding='none'>Tipo</TableCell>
                            <TableCell align="center" padding='none'>Estado</TableCell>
                            <TableCell align="center" padding='none'>Municipio</TableCell>
                            <TableCell align="center" padding='none'>Sección</TableCell>
                            <TableCell align="center" padding='none'>Dirección</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow className={classes.tableRow}>
                            <TableCell className={classes.tableCell} align="center">Dia</TableCell>
                            <TableCell className={classes.tableCell} align="left">Sin</TableCell>
                            <TableCell className={classes.tableCell} align="left">Comercios</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const contenido = sinDatos && datos.length != 0 ? tablaCompleta(datos) : tablaSinDatos()
    const tabla = espera ? (<Box mt={2}><LinearProgress/> </Box>) : (contenido)

    return (
        <Paper>
            {tabla}
            {modalMapa}
        </Paper>
    );
}