import React from 'react'
import {FormControl, Select, MenuItem, InputLabel,} from '@material-ui/core';

function SelectList({lista,value,onChange,etiq,etqVal,etqId,etqAlias,estilo,nameEtq}) {
  return (
    <FormControl  style={estilo} >
    <InputLabel id={`${etiq}-inpt-lbl`}>{`${etiq}`}</InputLabel>
    <Select  label={`${etiq}`} id={`${etiq}-slc-Id`} name={`${nameEtq}`}
        value={value} onChange={onChange}
    >
    {lista.map((lugar) => {
        if(lugar[etqId]>0) {
        return(
        <MenuItem value={lugar[etqVal]} key={lugar[etqId]}>
            <em>{lugar[etqAlias] }</em>
        </MenuItem>
        )}
    } )}     
    </Select>
</FormControl>     
  )
}

export default SelectList