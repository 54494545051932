import React,{useState,useEffect} from 'react'
import { TextField, FormControl, Box,//Tooltip,
        FormControlLabel,RadioGroup,Radio,//FormLabel,LinearProgress,   
        InputAdornment,IconButton,Input,     
        InputLabel,Select,MenuItem} from '@material-ui/core'  
//import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from 'axios';
import qs from 'qs';
import { useHistory } from "react-router-dom";
import ModalActualiza from '../generales/ModalCerrarActualiza';
//import moment from 'moment'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {llamaApiCargaToken,   ErrorGeneral, llamadaApiToken } from '../funciones/ClienteAxios';
import { toast } from 'react-toastify'; 
import { obtenDispo } from '../funciones/IdDispositivo';
import SelectList from './SelectList';

const EditarUsuario = ({modalAbierto,setModalAbierto ,titulo,setActualizar,actualizar,listTipoUsu,usrSelecc,subtitulo,recargar, listEstruc, }) => {
    let history = useHistory();   
    const source = axios.CancelToken.source();     
    const usuID = localStorage.getItem('UsuId') ? parseInt(localStorage.getItem('UsuId')) : 0; 
    const nvl= localStorage.getItem('Tipo') ? parseInt( localStorage.getItem('Tipo') ):0 ;       
    const auxDispV =obtenDispo()
    const[nvoRegis,setNvoRegis]= useState({Nombre:'', Email:'' ,Pass:'',Tel:'',TipoUsu:2,usuNick:'',
                                            idUsu:'',cargo:'', muni:"",Titular:"1",Superior:"",organismo:"",
                                            tpEstructra:'',observ:'', tipoSys:''
                                            });                                                
    //const [auxCorreo,setCorreo]= useState('');
    const [espera,setEspera]= useState(false);
    const [mensaje, setMensaje] =  useState("");
    const [tipoAdvertencia, setTipoAdvertencia] = useState("success");
    const [btnDeshabilitado,setBtnDeshabilitado]= useState(false)
    const [aviso, setAviso] = useState(false);
    const[visiblePwd,setVisiblePwd]= useState(false)    
    const[cambioEfectivo,serCambioEfectivo] = useState(false)    
    const[listSuperior,setListSuperior]= useState([]);    
    const[passOriginal,setPassOriginal]= useState("");    
    const[listOrg,setListOrg]= useState([]);   
    //const[listTp, setListTp] = useState([])      
    const[listTpEstrc, setListTpEstrc] = useState([])      
    const[listTipoSys, setListTipoSys] = useState([])      
    const{  Nombre, Email, Pass, Tel, TipoUsu, usuNick, idUsu, cargo, tpEstructra, Titular, Superior, 
            organismo, observ, tipoSys}=nvoRegis
        
    useEffect(()=>{
 
        setListTpEstrc(listEstruc) 
        getPass()
        getListOrg()
        getListTipoSys()
        return () => {
            source.cancel();
        }
    },[])
             
    useEffect(()=>{
        if (tpEstructra) {
            getListSuperior(tpEstructra)    
        }             
    },[tpEstructra])

    function getListOrg()  {            
        const url= `usuario/v2/estructura-organismo-list`;                            
        const data = qs.stringify({                
            usuario:usuID,                                                                        
            idDispositivo:auxDispV,
        });
                    
        function respuesta (aux) 
        {   if (aux[0].Id !== -1) 
            {   setListOrg(aux)
            }  
            else{
                setListOrg([])
            }                                                                    
        }
        llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    function getPass()  {            
        const url= `usuario/v2/estructura-pass`;                            
        const data = qs.stringify({                
            usuario:usrSelecc.UsrId,              
            idBase:usrSelecc.IdBase,                                                
            idDispositivo:auxDispV,
        });
                    
        function respuesta (aux) 
        {   if (aux.Info) 
            { 
                setNvoRegis({...nvoRegis,                        
                    Nombre: usrSelecc.Nombre, 
                    Email:usrSelecc.UsrEmail,
                    Tel:usrSelecc.UsrTel, 
                    TipoUsu:usrSelecc.UsrTipo, 
                    usuNick:usrSelecc.UsrUsuario,
                    idUsu:usrSelecc.UsrId, 
                    stdUsu:usrSelecc.Status, 
                    Titular: `${usrSelecc.UsrTitular}`,                        
                    tpEstructra:usrSelecc.TpEstrId,
                    Superior:usrSelecc.SupId, 
                    cargo:usrSelecc.Cargo,
                    Pass:aux.Info,
                    organismo:usrSelecc.OrgId,
                    observ:usrSelecc.Observ,
                    tipoSys:usrSelecc.SystId
                })        
                setPassOriginal(aux.Info)
            }                                                                  
        }
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral,setEspera, history, source);
    };
    
    function getListSuperior(tipo)  {            
        const url= `usuario/v2/estructura/superior`;                            
        const data = qs.stringify({                
            usuario:usuID,           
            tipo:tipo,               
            idDispositivo:auxDispV,
        });
                    
        function respuesta (aux) 
        {   if (aux[0].Id != -1) 
            {                                   
                let filtro=[]
                aux.forEach(element => {
                    if (element.Id>0 ) {
                        filtro.push(element)
                    }
                });                
                setListSuperior(filtro)
            }     
            else{
                setListSuperior([])
            }                                                                 
        }
        llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    function getEditUsu()  {          
        const url=`usuario/v3/estructura/usuario/edit`;                                                                       
        const data = qs.stringify({                         
            idUsuario:idUsu,
            usuario:usuID,
          //  tipo:TipoUsu,
            titular:Titular,
            usrUsr:usuNick,
            usrPass:Pass,
            usrNom:Nombre,
            email:Email,
            telefono:Tel,  
            system:tipoSys,          
            idDispositivo:auxDispV
        }); 
        
        function respuesta (aux) {              
          if (aux.length!==0) 
          {                            
            recargar()       
            setBtnDeshabilitado(true)                                             
            let mensaje="Se edito correctamente el Usuario."
            setTipoAdvertencia("success")      
            setMensaje(mensaje);
            setAviso(true)     
            serCambioEfectivo(true)
            toast.success(mensaje,{
                pauseOnFocusLoss: false,                    
                toastId: `edit-usuario${1}`
            })
            setModalAbierto(false)
          }                                            
        }
        llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);             
    };

    const getListTipoSys = () => {
        const data = qs.stringify({
            usuario: usuID, idDispositivo: auxDispV,
        });
        const url = "usuario/v1/system";

        function respuestaSecc(auxiliar) {
            if (auxiliar[0].Id !== -1) {                
                setListTipoSys(auxiliar)
            }
        }

        llamaApiCargaToken(data, url, respuestaSecc, ErrorGeneral, setEspera, history, source);
    }

    function getEditEstruc(tipo)  {          
        const url=`usuario/v2/estructura-edit`;                                                                       
        const data = qs.stringify({                        
            idBase:usrSelecc.IdBase,
            idUsuario:idUsu,
            usuario:usuID,
            tipo:tpEstructra,
            superior:Superior,
            nombre:Nombre,
            cargo:cargo,
            organismo:organismo,
            observacion:observ,            
            idDispositivo:auxDispV
        }); 
        
        function respuesta (aux) {              
          if (aux.length!==0) 
          {                            
            if (tipo===1) {
                recargar()       
                setBtnDeshabilitado(true)                                             
                const mensaje="Se edito correctamente el Usuario."
                setTipoAdvertencia("success")      
                setMensaje(mensaje);
                setAviso(true)     
                serCambioEfectivo(true)
                toast.success(mensaje,{
                    pauseOnFocusLoss: false,                    
                    toastId: `edit-usuario${1}`
                })
                setModalAbierto(false)
            }
           
          }                                            
        }
        llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);             
    };

    const onChange = e =>
	{
        let expNopermitida = new RegExp('[%{}*|`]');  
        let expMenos = new RegExp("'");
        let expMas = new RegExp('"');             
        let expCadena = new RegExp('[A-Za-z0-9.$@#_&-+()/:;!?,<>=]');  
        if ( ( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
                && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
                || e.target.value===' '|| e.target.value==='') 
        {
            setNvoRegis({ ...nvoRegis, [e.target.name] : e.target.value})            
        }		
	};      

    const onChangeNumTel = e =>
	{   let expNopermitida= new RegExp('[A-Za-z$@#_&-+()/:;!?,<>=]');     
        let expMenos = new RegExp("'");
        let expMas = new RegExp('-');     
        let expCadena = new RegExp('[.0-9$]');             
        if (  e.target.value.length<=10 &&( expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) 
            && !expMenos.test(e.target.value) && !expMas.test(e.target.value) ) 
            || e.target.value===' '|| e.target.value==='') 
        {
            setNvoRegis({
                ...nvoRegis, 
                [e.target.name] : e.target.value//.toUpperCase()  
            })            
        }		    

	}; 

    const verificaNulos = (campo,descripcion)=>{
        let respuesta =false;        
          
        if( (typeof campo !== 'number' && typeof campo === 'string' 
            && (campo.trim() === '' || campo.trim() === null) )
        ) 
        {   respuesta=true;               
            setMensaje('No se lleno el campo '+descripcion);
            setTipoAdvertencia("warning")  
            setAviso(respuesta);
        }
        return(respuesta);
    }

    const mandaAlerta=(mensaje,tipoAdvert)=>
    {
        setMensaje(mensaje);
        setTipoAdvertencia(tipoAdvert)  
        setAviso(true);      
    }
 
    function valContra() {
        let respuesta=false
        if ( !verificaNulos(Pass,'Contraseña') ) 
        {            
            if (Pass.length>=4 ) 
            {
                respuesta=true    
            }else
            {                
                mandaAlerta('Campo Contraseña incompleto, debe escribir una Contraseña valida',"warning") 
            }
        }        
        return respuesta;
    }

    function validaVariable(auxCampo,auxMensaje,auxTamanio) 
    {   let respuesta=false
        if ( !verificaNulos(auxCampo,auxMensaje) ) 
        {   if (auxCampo.length>=auxTamanio ) 
            {                
                respuesta=true                    
            }else
            {            
                mandaAlerta('Campo '+auxMensaje +' incompleto, debe escribir un '+ auxMensaje+' valido',"warning") 
            }
        }        
        return respuesta;
    }
  
    const filtroApi =()=>{ 
        let api1=false,api2=false  
        if (usrSelecc.Nombre!==Nombre) 
        {
            api1=true
            api2=true            
        }
        if (usrSelecc.SystId!==tipoSys) 
        {
            api1=true                 
        }
        if (!api1 && `${usrSelecc.UsrTipo}`!== `${TipoUsu}`) 
        {
            api1=true
        }
        if (!api1 && `${usrSelecc.UsrTitular}`!== `${Titular}`) 
        {
            api1=true
        }        
        if (!api1 && `${usrSelecc.UsrUsuario}`!== `${usuNick}`) 
        {
            api1=true
        }
        if (!api1 && `${passOriginal}`!== `${Pass}` ) 
        {
            api1=true
        }
        if (!api1 && `${usrSelecc.UsrEmail}`!== `${Email}` ) 
        {
            api1=true
        }
        if (!api1 && `${usrSelecc.UsrTel}`!== `${Tel}` )
        {
            api1=true
        } 
        
        if (!api2 && `${usrSelecc.TpEstrId}`!== `${tpEstructra}`) 
        {
            api2=true
        }
        if (!api2 &&  `${usrSelecc.SupId}`!== `${Superior}`) 
        {
            api2=true
        }
        if (!api2 && `${usrSelecc.Cargo}`!== `${cargo}`) 
        {
            api2=true
        }
        if (!api2 && `${usrSelecc.OrgId}`!== `${organismo}`)
        {
            api2=true
        }        
        if (!api2 && `${usrSelecc.Observ}`!== `${observ}` )
        {
            api2=true
        }        
        if (api1 && api2) {
            getEditUsu()
            getEditEstruc(2)
        }
        else{
            if (api1) {
                getEditUsu()                
            }
            if (api2) {
                getEditEstruc(1)
            }
        }
    }

    function guardarDatos(e)
    {   e.preventDefault()        
 
        if (//!verificaNulos(TipoUsu,'Permiso') && 
            !verificaNulos(tpEstructra,"Estructura")    
            && !verificaNulos(Superior,'Superior') && validaVariable(cargo,'Descripción de Usuario',5)
            &&  !verificaNulos(organismo,'Organismo') 
            && validaVariable(Nombre,'Nombre',7) &&validaVariable(usuNick,'Usuario',4)
            && valContra()  && validaVariable(Tel,'Teléfono',10) 
            )
        { 
            setMensaje('');                
            setAviso(false);          
            filtroApi()            
            //getEditUsu()                        
        }                                  
    }   
 
    const guardarTipoSelect=(e)=>{    
        if (e!== null && e!== undefined) 
        { //setMuniSelect(e)       
            setNvoRegis({ ...nvoRegis, TipoUsu :e.target.value  })      
        }else
        {   setNvoRegis({ ...nvoRegis, TipoUsu : ""   })           
        }        
    }

    const mostrarPwd = () => {        
        setVisiblePwd(!visiblePwd)
    };
    
    const mostrarPwdUp = (event) => {
        event.preventDefault();    
    };

    const onChangeRad=(e)=>{
        setNvoRegis({...nvoRegis,[e.target.name] : e.target.value,   Superior : ""  })            
    }

    const filtroSlct=(e)=>{
        let arr=[]
        switch (e.target.name) {        
            case 'tpEstructra':
                arr={...nvoRegis,[e.target.name]:e.target.value, Superior:'' }
                break;            
            default:
                arr= {...nvoRegis,[e.target.name]:e.target.value}
                break;
        }
        setMensaje('');                
        setAviso(false);   
        setNvoRegis(arr)
    }

    const onChangeSlct=(e)=>{      
        
        if (e!== null && e!== undefined) 
        {   //setNvoRegis({...nvoRegis,[e.target.name] : e.target.value})  
            filtroSlct(e)
        }
        else
        {   setNvoRegis({...nvoRegis,[e.target.name]:""})          
        }        
    }
    
    const slctSyst=()=>{
        return<FormControl  style={{minWidth:'8rem'}} >
            <InputLabel id={`Sistema-inpt-lbl`}>Sistema</InputLabel>
            <Select  label={`Sistema-labl`} id={`Sistema-slc-Id`} name={`tipoSys`}
                value={tipoSys} onChange={onChange}
            >
            {listTipoSys.map((lugar) =>                                  
                <MenuItem value={lugar['Id']} key={lugar['Id']}>
                    <em>{lugar['Nom'] }</em>
                </MenuItem>                
            )}     
            </Select>
        </FormControl>  
    }

 /*    const slctTipoEstrc =  listTpEstrc.length > 0 ?                       
    <SelectList lista={listTpEstrc} value={tpEstructra} onChange={onChangeSlct} 
        etqVal={"Id"} etqId="Id" etqAlias={"Alias"} etiq={"Estructura"} nameEtq={"tpEstructra"}
        estilo={{paddingRight:1+'rem', minWidth:14+'rem',paddingBottom:1+'rem'}} 
    />            
    :null   */

    const slctSuperior =  listSuperior.length > 0 ?   
    <div> 
    <SelectList lista={listSuperior} value={Superior} onChange={onChangeSlct} 
        etqVal={"Id"} etqId="Id" etqAlias={"Nom"} etiq={"Superior"} nameEtq={"Superior"}
        estilo={{paddingRight:1+'rem', minWidth:14+'rem',paddingBottom:1+'rem'}} 
    />   <br/>
    </div>                             
    :null  

    const slctOrg =                        
    <SelectList lista={listOrg} value={organismo} onChange={onChangeSlct} 
        etqVal={"Id"} etqId="Id" etqAlias={"Descr"} etiq={"Organismo"} nameEtq={"organismo"}
        estilo={{minWidth:15+'rem'}} 
    />  

    const DatosGenerales=(
        <div>
            <form autoComplete='off' > 
            {listTipoSys.length!==0? <Box> {slctSyst()} </Box>: null}
            { /*  listTp.length!==0 &&nvl!==undefined && nvl === 1 ?                
               <FormControl  style={{paddingRight:1+'rem', minWidth:14+'rem',paddingBottom:1+'rem'}} >
                   <InputLabel id="tipo">Permiso</InputLabel>
                   <Select  
                     label="Permiso" id="TipoUsu" name="TipoUsu"
                     value={TipoUsu} onChange={guardarTipoSelect}
                   >
                    {listTp.map((secc, index) => {
                        if(secc.Id>0) {
                        return(
                        <MenuItem value={secc.Id} key={secc.Id}>
                            <em>{secc.Nom }</em>
                        </MenuItem>
                        )}
                    } )}                    
                   </Select>
                </FormControl>
            :   null
               */}
          
            {/* slctTipoEstrc */}

            { nvl===1  ? 
                <div>        
                <FormControl component="fieldset" style={{marginTop:".5rem" }}>                             
                    <RadioGroup  row aria-label="position"  defaultValue="top"
                        name="Titular" value={Titular} onChange={onChangeRad}
                    >   
                        <FormControlLabel value={"1"}  label={"Titular"}     
                            control={<Radio color="primary" />} />                                 
                        <FormControlLabel value={"0"}  label={"Encargado"}             
                            control={<Radio color="primary" />} />                                                                                  
                    </RadioGroup>                     
                </FormControl>  
                </div>
            :null  }
            
            {slctSuperior/*  */}
            
   
            <TextField required id="cargo-input" onChange={onChange} 
                style={{paddingBottom:.5+'rem',width:25+'rem',marginTop:".5rem" }}
                label="Descripción de Usuario" value={cargo} name='cargo'size="small" autoComplete='off' // defaultValue="Nombre"                
            />
            <br/>
            {slctOrg}
            <br/>
            <TextField required id="Nombre-input" onChange={onChange} style={{paddingBottom:.5+'rem',width:23+'rem'}}
                label="Nombre" value={Nombre} name='Nombre'size="small" // defaultValue="Nombre"                
                autoComplete='off'
            />
            <br/>
            <TextField  id="usu-input1" onChange={onChange} style={{width:11.5+'rem',paddingRight:.8+'rem',paddingBottom:.5+'rem',}}
                label="Usuario"  value={usuNick} name='usuNick' size="small"//defaultValue="Usuario"
                autoComplete='off' disabled={usuID!==1?true:false}
            />      
  
            <FormControl  size="small" style={{width:11.5+'rem'}} >
                <InputLabel htmlFor="pwd-usu1-txt" size="small">
                    Contraseña
                </InputLabel>
                <Input
                    id="input-p-usu1"  disabled={usuID!==1?true:false}
                    type={visiblePwd ? 'text' : 'password'}
                    value={Pass}  autoComplete='do-not-autofill' name="Pass"
                    onChange={onChange } size="small"
                    endAdornment={
                        <InputAdornment position="end" size="small">
                            <IconButton size="small" 
                                aria-label="icono password visibility"
                                onClick={mostrarPwd}  onMouseDown={mostrarPwdUp}
                            >
                            {visiblePwd ? <Visibility size="small"/> : <VisibilityOff size="small"/>}
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>  
            <br/>
            <TextField  id="CInt-input" onChange={onChangeNumTel} style={{paddingRight:1+'rem',width:7.5+'rem'}}
                label="Teléfono" defaultValue="Tel" value={Tel} name='Tel' size="small" type="number"
               inputProps= {{ maxlength: 10 }}
               autoComplete='off'
            /> 
            <TextField  id="Email-input" onChange={onChange} style={{paddingBottom:1+'rem',width:15.5+'rem'}}
                label="Email"  value={Email} name='Email' size="small"//defaultValue="Usuario"
                autoComplete='off'
            /> 
            <br/>
            <TextField  id="observ-input" onChange={onChange} autoComplete='off'
                label="Observaciones"  value={observ} name='observ' size="small" inputProps= {{ maxLength: 50 }}                
                fullWidth multiline maxRows={3} style={{width:"27rem"}} variant='outlined'
            />    
        </form>
        </div>
 
    )       
    const cerrarBorrar = () => {    
        //setOpen(false);        
        setAviso(false);
        setModalAbierto(false);
        if (cambioEfectivo) 
        {
            setActualizar(!actualizar)
        }
    };

    return (
        <>           
         <ModalActualiza
            titulo={titulo} modalAbierto={modalAbierto}
            setModalAbierto={setModalAbierto} guardarDatos={guardarDatos}
            mensaje={mensaje} tipoAdvertencia={tipoAdvertencia}
            aviso={aviso} btnDeshabilitado={btnDeshabilitado}
            setAviso={setAviso} tamanio={'md'} cargando={espera}      
            subtitulo={subtitulo}  cerrarBorrar={cerrarBorrar}
        >
            {DatosGenerales}
        </ModalActualiza> 
            
        </>
    )
}

export default EditarUsuario
