import React,{useState} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {Card,CardContent,Box} from "@material-ui/core";
import NumberFormat from 'react-number-format';
const useStyles = makeStyles((theme) => ({
    card:{
        height:'4.1rem',
        width:130,
        // padding:'1.1 rem 0px 1.12rem',
        marginRight:'.5em',
    },
    card2:{
        height:'4.1rem',
        width:230,
       // padding:'1.1 rem 0px 1.12rem',
        marginRight:'.5em',
      },
    card3:{
        height:'4.1rem',
        width:180,
        // padding:'1.1 rem 0px 1.12rem',
        marginRight:'.5em',
    },
    titulo:{
        //color:'#4a4a4a',
        textAlign:'center',
        marginTop:'-.4rem',
        fontSize:'1rem',
        fontWeight:600
    },
    tituloColor:{

        //color:'white',
        textAlign:'center',
        marginTop:'-.6rem',
        fontSize:'1.2rem',
        fontWeight:600
    },
    subtitulo:{
        //color:'#4a4a4a',
        textAlign:'center',
        marginTop:'-1.4rem',
        fontSize:'.8rem',
    },
    subtituloCard:{
       // color:'#4a4a4a',
        textAlign:'center',
        marginTop:'.28rem',
        fontSize:'1.1rem',
    },
}));


export default function CardTotal({valor}){
    const classes = useStyles();
    const[total,setTotal]= useState([])
    const auxColor=process.env.REACT_APP_Fondo_Color
    React.useEffect(()=>{    
        const auxGralMun=valor.MunAvc 
        const auxGralSec=valor.SecAvc               
        const auxTotMun=valor.MunTot 
        const auxTotSec=valor.SecTot        
        setTotal({
            gralMun:auxGralMun,gralSec:auxGralSec,
            totMun:auxTotMun,totSec:auxTotSec,             
        })
     },[valor])  

    function generarCards(mensaje,datos,auxColor){
        return( 
        <CardContent>
            <Card className={classes.tituloColor} 
                style={{backgroundColor:auxColor,color:'white'}} 
            >                
                <NumberFormat value={datos} displayType={'text'}  thousandSeparator={true}/>
            </Card>
            <p className={classes.subtituloCard} >
                {mensaje}
            </p>
        </CardContent>
        )
    }
    function generarCards2(mensaje,datGen,datTot,auxColor){
        return( 
        <CardContent>
            <Card className={classes.tituloColor} 
                style={{backgroundColor:auxColor,color:'white'}} 
            >                
                <NumberFormat value={datGen} displayType={'text'}  thousandSeparator={true}/>
                {' / '}
                <NumberFormat value={datTot} displayType={'text'}  thousandSeparator={true}/>
            </Card>
            <p className={classes.subtituloCard} >
                {mensaje}
            </p>
        </CardContent>
        )
    }
    return (         
    <Box display="flex" flexDirection="row" mb={1}  >   
        <Box display="flex" flexDirection="row">                                    
            <Card className={classes.card}>
                {generarCards2('Municipio',total.gralMun, total.totMun,auxColor)}
            </Card>
            <Card className={classes.card3}>
                {generarCards2('Sección',total.gralSec, total.totSec,auxColor)}
            </Card>       
        </Box>   
    </Box>
    );
}
 