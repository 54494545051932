import React,{useState,useEffect} from 'react';
import {Box, LinearProgress,Select,FormControl,InputLabel,
    MenuItem, TextField
} from "@material-ui/core";
import TablaSugePublico from './TablaSugePublico';
import axios from "axios";
import qs from "qs";
import moment from 'moment';
import { useHistory } from "react-router-dom";
import IntervaloSelect from './IntervaloTabla';
import IconBuscar from '../generales/Iconos/IconBuscar';
import {llamadaApiToken,llamaApiCargaToken,ErrorGeneral } from '../funciones/ClienteAxios';
import {GeneralesContext} from '../context/GeneralesContext'
import SelectList from '../generales/SelectList';
import { obtenDispo } from '../funciones/IdDispositivo';
import {findNumberCad} from '../funciones/FindNumber'
const SugerenciaPublico = () => {
    let history = useHistory();         
    const auxDispV =obtenDispo()      
    const source = axios.CancelToken.source();
    //let token =localStorage.getItem('token20') ;  
    const usuId =localStorage.getItem('UsuId') ;     
    const cadena = localStorage.getItem("Operacion")+"|"; 
    const {generales,guardarGenerales}=React.useContext(GeneralesContext);                    
    const [listSuge, setListSuge] = useState([])
    const [listTipo, setListTipo] = useState([])
    const [listStatus, setListStatus] = useState([])
    const[listMun, setListMun] = useState([])   
    const[listSecc, setListSecc] = useState([])  
    const[listTipoDet, setListTipoDet] = useState([])  
    const[listExcel, setListExcel] = useState([])  
    const [fSelect, setFSelect] = useState({inicio:new Date(),final:new Date()});    
    const [statusSug, setStatusSug] = useState(0)
    const [filtro, setFiltro] = useState({Nombre:"", Municipio:0, Seccion:"",tipoDet:"", Descr:""})
    const [tipoSug, setTipoSug] = useState(0)
    const [espera, setEspera] = useState(false)
    const [actualizar, setActualizar] = useState(false)
    const {Nombre, Municipio, Seccion,tipoDet, Descr}= filtro

    useEffect(() => {                
                
        if (usuId !== null && usuId !== undefined  ) 
        {
            llenaListTipo()
            llenaListStatus()    
            guardarGenerales({...generales,Titulo:'Solicitudes de Atención'}) 
            getListMuni(15)   
        }
        else
        {   
            localStorage.clear();                            
            history.push("/");  
        }
        
        return ()=> {
            source.cancel();
        }  
    }, [])

    useEffect(() => {
        if (Municipio) {
            if (Municipio!==0) {
                getListSecc(Municipio)    
            }
            else{
                setListSecc([])
            }
            
        }
            
    }, [Municipio])

    useEffect(() => {
        if (tipoSug) {
            if (tipoSug!==0) {
                getListTipoDet(tipoSug)    
            }           
            
        }
            
    }, [tipoSug])

    useEffect(() => {                        
        llenaListSug()
    }, [actualizar])

    function getListMuni(auxEdo)  {            
        const url= `lugar-municipio`;                            
        const data = qs.stringify({                
            usuario:usuId,              
            estado:auxEdo,                                                
            idDispositivo:auxDispV,
        });
                    
        function respuesta (aux) 
        {   if (aux[0].Id !== -1) 
            {  
                const auxTodo={EdoId: 15,Id: 0,Nom: "Todos",
                Numero: 0}
                const arr=[]
                arr.push(auxTodo)          
                aux.forEach(element => {
                    arr.push(element)
                });
                setListMun(arr)                           
            }  
            else
            {   setListMun([])
            }                                                                    
        }
        llamadaApiToken(data, url, respuesta, ErrorGeneral, history, source);
    };

    const getListSecc = (auxMuni) => {
        const data = qs.stringify({
            usuario: usuId, municipio: auxMuni, idDispositivo: auxDispV,
        });
        const url = "lugar-seccion";

        function respuestaSecc(auxiliar) {
            if (auxiliar[0].Id !== -1) {     
                const auxTodo=[{DtoFedId:1,
                    DtoFedNom: 1,
                    DtoLocId: 55,
                    DtoLocNom: 0,
                    Id: 0,
                    MuniId: auxMuni,
                    Mzn: 1 ,
                    Numero: "Todos",
                    Rechazo: 0,
                    Registro: 0,
                    Valido: 0}]
                                
                auxiliar.forEach(element => {
                    auxTodo.push(element)
                });           
                setListSecc(auxiliar)
            }else{
                setListSecc([])
            }
        }

        llamadaApiToken(data, url, respuestaSecc, ErrorGeneral, history, source);
    }

    const getListTipoDet = (auxLlave) => {            
        const data = qs.stringify({
            usuario:usuId, llave:auxLlave,                               
            idDispositivo: auxDispV,
        });
        const url = "solicitud/tipo/detalle";
        function respuestaListTipo(auxiliar) {
            if (auxiliar[0].Id !== -1 ) {             
                setListTipoDet(auxiliar);      
            }
        }
        llamadaApiToken(data, url, respuestaListTipo,ErrorGeneral,history, source);
    };

    const llenaListStatus = () => {            
        const data = qs.stringify({
            usuario:usuId,                                
            idDispositivo: auxDispV,
        });
        const url = "solicitud/status";
        function respuestaListStat(auxiliar) {
            if (auxiliar[0].Id !== -1 ) {             
                setListStatus(auxiliar);      
            }
        }
        llamadaApiToken(data, url, respuestaListStat,ErrorGeneral,history,source);
    };

    const llenaListSug = () => {
        const fInicio=moment(fSelect.inicio).format("YYYY-MM-DD");
        const fFinal=moment(fSelect.final).format("YYYY-MM-DD");
        const data = qs.stringify({
            usuario:usuId,
            fini:fInicio+"T00:00:00",
            ffin:fFinal+"T23:59:59",
            status:statusSug.length!==0 ? statusSug:0,
            tipo:tipoSug,
            tipoDet:tipoDet!==""?tipoDet:0,
            municipio:Municipio!==""?Municipio:0,
            seccion:Seccion!==""?Seccion:0,
            solicita:Nombre,
            descr:Descr,
            idDispositivo: auxDispV,
        });
        const url = "solicitud/lista/filtro";
        function respuestaListSug(auxiliar) {
            if (auxiliar[0].Id !== -1) {
                if (findNumberCad('|36|',cadena) ) {
                    const  arrM=[["#","Fecha Registro", "Folio", "Estatus", "Tipo", "Detalle", "Municipio","Sección","Registro",
                        "Asignado","Nacimiento","Genero","Teléfono","Nombre","Solicitud","Email"]]              
                    auxiliar.forEach((row,index) => {
                        const auxFReg =
                            moment.utc(row.Fecha).format("YYYY-MM-DD HH:mm") === "1900-01-01 00:00"
                            ? ""
                            : moment.utc(row.Fecha).format("DD/MM/YYYY HH:mm");  
                        const auxFNac =
                            moment.utc(row.Fnac).format("YYYY-MM-DD HH:mm") === "1900-01-01 00:00"
                            ? ""
                            : moment.utc(row.Fnac).format("DD/MM/YYYY");
                        const auxMuni = row.MunNom.charAt(0) + row.MunNom.slice(1).toLowerCase();
                        arrM.push([index+1,auxFReg, row.Folio, row.StatusNom, row.TipoNom, row.TipoDtNom, auxMuni,
                        row.SeccNum, row.UserReg, row.Asignado, auxFNac, obtenerGen(row.Genero), row.Tel,
                        row.Nombre, row.Mensaje, row.Email])
                    });
               
                    setListExcel(arrM)    
                }
                
                setListSuge(auxiliar);      
            }else{
                setListSuge([]);      
            }
        }
    
        llamaApiCargaToken(data, url, respuestaListSug,ErrorGeneral,setEspera,history,source);
    };

    const llenaListTipo = () => {            
        const data = qs.stringify({
            usuario:usuId,                                
            idDispositivo: auxDispV,
        });
        const url = "solicitud/tipo";
        function respuestaListTipo(auxiliar) {
            if (auxiliar[0].Id !== -1 ) {             
                setListTipo(auxiliar);      
            }
        }
        llamadaApiToken(data, url, respuestaListTipo,ErrorGeneral,history,source);
    };

    const obtenerGen=(aux)=>{
        let respuesta=""
        switch (aux) {
          case 'H':
            respuesta="Hombre"
            break;
          case 'M':
            respuesta="Mujer"
            break;
          case 'X':
            respuesta="No Binario"
            break;
          case 'N':
            respuesta="Pendiente"
            break;
          default:
            break;
        }
        return respuesta
      }
    
    const btnBuscar=(e)=>{
        e.preventDefault()
       // llenaListSug()
       setActualizar(!actualizar)
    }           

    const onChangeSlct=(e)=>{            
        if (e!== null && e!== undefined) 
       {   
            if (e.target.name==="Municipio") {
                setFiltro({...filtro,Seccion:"",
                [e.target.name]:e.target.value})
                setListSecc([])
            }else{
                setFiltro({...filtro,[e.target.name]:e.target.value})   
            }
            
         
       }
       else
       {   setFiltro({...filtro,[e.target.name]:""})          
       }        
   }

    const onChangeStatus=(e)=>{            
        if (e!== null && e!== undefined) 
        { setStatusSug( e.target.value )                  
        }
        else
        { setStatusSug("")                  
        }        
    }  

    const onChangeTipo=(e)=>{            
        if (e!== null && e!== undefined) 
        {   setTipoSug( e.target.value )    
            setFiltro({...filtro,tipoDet: ""})    
            setListTipoDet([])          
        }
        else
        { setTipoSug("")                  
        }        
    }

    const onChange = e => {
        setFiltro({...filtro,[e.target.name]: e.target.value})
    }

    const onChangeTipoDet = (e) => {
        setFiltro({...filtro,[e.target.name]: e.target.value});
     
    };

    const slctMun =  listMun.length > 0 ?  
    <FormControl size='small'  style={{ minWidth:14+'rem', marginTop:".5rem"}} >
    <InputLabel size='small' variant="outlined" id="Municipio-label-nvo">
        Municipio</InputLabel>
    <Select  size='small' variant="outlined"
        label="Municipio" id="Municipio-select2" name="Municipio"
        value={Municipio} onChange={onChangeSlct}
    >
        {listMun.map((muni, index) => {             
            return(
                muni.Id ===0 ?  
            <MenuItem size='small' value={muni.Id} key={muni.Id}>
                <em>Todos</em>
            </MenuItem>
            :<MenuItem size='small' value={muni.Id} key={muni.Id}>
                <em>{muni.Nom }</em>
            </MenuItem>
            )
        } )}                    
    </Select>
    </FormControl>                     
             
    :null 

    const slcSec=listSecc.length!==0 ? 
    <FormControl size='small'  style={{ minWidth:7+'rem', marginTop:".5rem"}} >
    <InputLabel size='small' variant="outlined" id="Seccion-label-nvo">
    Sección</InputLabel>
    <Select  size='small' variant="outlined"
        label="Sección" id="Seccion-select2" name="Seccion"
        value={Seccion} onChange={onChangeSlct}
    >
        {listSecc.map((secc, index) => {             
            return(
                secc.Id ===0 ?  
            <MenuItem size='small' value={secc.Id} key={secc.Id}>
                <em>Todos</em>
            </MenuItem>
            :<MenuItem size='small' value={secc.Id} key={secc.Id}>
                <em>{secc.Numero }</em>
            </MenuItem>
            )
        } )}                    
    </Select>
    </FormControl> 
      :null

    return (
    <div style={{marginLeft:.5+'rem'}} >
        <Box display={'flex'} mt={2} mb={1}>
            <Box >
                <IconBuscar leyenda="Buscar" handleClick={(e)=>btnBuscar(e)} />                                                                                                                        
            </Box>    
            <Box>
                <IntervaloSelect fSelect={fSelect}  setFSelect={setFSelect} />
            </Box>
            <Box ml={1}>
            {slctMun}
            </Box>
            <Box ml={1}>
            {slcSec}
            </Box>
            {listStatus.length!==0 ?                
            <Box display={"flex"} >
            <FormControl size='small'  style={{marginLeft:1+'rem',marginTop:.4+'rem', minWidth:7.5+'rem'}} >
                <InputLabel size='small' variant="outlined" id="estatus-label-nvo">Estatus</InputLabel>
                <Select  size='small' variant="outlined"
                    label="Estatus" id="statusSug-select2" name="statusSug"
                    value={statusSug} onChange={onChangeStatus}
                >
                    {listStatus.map((secc, index) => {
                         
                        return(
                        secc.Id ===0 ?  
                            <MenuItem size='small' value={secc.Id} key={secc.Id}>
                                <em>Todos</em>
                            </MenuItem>
                            :<MenuItem size='small' value={secc.Id} key={secc.Id}>
                                <em>{secc.Nom }</em>
                            </MenuItem>
                        )
                    } )}                    
                </Select>
                </FormControl>
                </Box>
            :   null
            }
            {listTipo.length!==0 ?                
            <Box display={"flex"} >
            <FormControl size='small'  style={{marginLeft:1+'rem',marginTop:.4+'rem', width:8.5+'rem'}} >
                <InputLabel size='small' variant="outlined" id="tipoSug-label-nvo">Tipo</InputLabel>
                <Select  size='small' variant="outlined"
                    label="Tipo" id="tipoSug-select2" name="tipoSug"
                    value={tipoSug} onChange={onChangeTipo}
                >
                    {listTipo.map((secc, index) => {
                        return(
                            secc.Id ===0 ?                        
                            <MenuItem size='small' value={secc.Id} key={secc.Id}>
                                <em>Todos</em>
                            </MenuItem>                         
                            :
                            <MenuItem size='small' value={secc.Id} key={secc.Id}>
                                <em>{secc.Nom }</em>
                            </MenuItem>                         
                        )
                    } )}                    
                </Select>
                </FormControl>
                </Box>
            :   null
            }
            {listTipoDet.length!==0?
            <Box ml={1}>
           
            <FormControl size='small'  style={{ marginTop:.4+'rem', width:8.5+'rem'}} >
                <InputLabel size='small' variant="outlined" id="tipoDet-label-nvo">Detalle</InputLabel>
                <Select  size='small' variant="outlined"
                    label="Detalle" id="tipoSug-select2" name="tipoDet"
                    value={tipoDet} onChange={onChangeTipoDet}
                >
                    {listTipoDet.map((secc, index) => {
                        return(
                     
                            <MenuItem size='small' value={secc.Id} key={secc.Id}>
                                <em>{secc.Nom }</em>
                            </MenuItem>                         
                        )
                    } )}                    
                </Select>
                </FormControl>
            </Box>
            :null}
            <Box pl={1} pt={1}>
                <TextField id="nombre-txt" name="Nombre" label="Nombre"   size="small"
                    value={Nombre} onChange={onChange} variant="outlined"/>  
          </Box>
          <Box pl={1} pt={1}>
                <TextField id="Descr-txt" name="Descr" label="Descripción"   size="small"
                    value={Descr} onChange={onChange} variant="outlined"/>  
          </Box>
        </Box>
        { !espera  ? <TablaSugePublico listSuge={listSuge} listTipo={listTipo} listStatus={listStatus} 
                        actualizar={actualizar} setActualizar={setActualizar} listExcel={listExcel} setListSuge={setListSuge}/>
            :    <Box pt={3}><LinearProgress/> </Box>
        }
        
    </div>
    );
};

export default SugerenciaPublico;