import React from 'react'
import moment from 'moment';
 
const Reporte=({urlReport, informacion, nombre, mostrarTB, setArchivo})=>{
    let today = new Date();                    
    let fec = moment(today);
    React.useEffect(()=>{
        let Stimulsoft = window.Stimulsoft;
        Stimulsoft.Base.StiLicense.key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHl2Nbzg4HdTNm8R4+DL0SOlyjrr1Ca93XZRSF451sMrUQUbIC" + 
        "Rc4TZ6dJAire9aEpoaC1IiI6x5VLHaMglc1s2k4uRm4dUYTjqhd9tzwg51DplBBlocu7D5g5ObpQQ48TKP6bm0KtDs" + 
        "IvGW90HJ8m3O/KqEDnPXDYxuMiAVMZm7N1AsPVnk8wX2vhYjKVE8JJciqq6ves7LcM9CixolwKzMHJRyNLh/RL+0Rp" + 
        "/IZSOKcqneLtLhVtiYYXhWNvjKOjkUDvmzngVAuJ7WTtYx4bHfnjqcmOtnSpXX0KyPanw5zxMkdkeoWsU3u3hQTSOL" + 
        "9omRDSvCw4xMAdFVnsNI754ALmBQx0d17rjPlWbIEQ1nN0L2HE0S8bveNQUkWpyUDscl0MxOzoHyAJ7Bf/3DgmVpdE" + 
        "uVVeinsKXs6gsTIUyOVrMFro/cdNfb7NzgaY7GkRGLeGke+AGiCTyMs8jfaH5atn0U/EPb9ZeuMx1xCpgEbh+ROVii" + 
        "gsQlBiHxeeVRHD2Do/lqZXN4401UNbjOlg24";  
        //Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile("localization/es.xml", true);
        //Stimulsoft.Base.StiFontCollection.addOpentypeFontFile("Roboto-Black.ttf");
        let report = Stimulsoft.Report.StiReport.createNewReport();
        report.loadFile(urlReport);

        let dataSet = new Stimulsoft.System.Data.DataSet("Carta");
        dataSet.readJson(informacion);
        report.regData(dataSet.dataSetName, "", dataSet);
        let options = new Stimulsoft.Viewer.StiViewerOptions();

        options.toolbar.showOpenButton = false;          
        options.toolbar.showDesignButton = false;  
        options.toolbar.showAboutButton = false;
        options.toolbar.viewMode= Stimulsoft.Viewer.StiWebViewMode.Continuous    
        options.appearance.scrollbarsMode = true;
        options.toolbar.visible = mostrarTB;//mostrar la barra de herramientas

        options.exports.showExportToHtml=false;
        options.exports.showExportToHtml5=false;
        options.exports.showExportToCsv=false;
        options.exports.showExportToJson=false;
        options.exports.showExportToText=false;
        options.exports.showExportToOpenDocumentWriter=false;
        options.exports.showExportToOpenDocumentCalc=false;        
        options.exports.showExportToPowerPoint=false;
        options.exports.showExportToImageSvg=false;
        options.exports.showExportToExcel2007=false;      
        options.exports.showExportToDocument=false;      
        options.appearance.showTooltipsHelp=false;
        
        //options.toolbar.showSendEmailButton = true;
        let viewer = new Stimulsoft.Viewer.StiViewer(options, "StiViewer", false);
        const tituloPDF=fec.format("YYMMDDHHmm")+nombre
        report.reportName=tituloPDF
        /*viewer.onBeginExportReport = function (event) {        
            console.log("printing");            
        }*/
        report.renderAsync(() => {
            // c("renderAsync");
             console.log("renderAsync.");
             // Exporting the report to PDF
             report.exportDocumentAsync((pdfData) => {
                report.exportDocument(Stimulsoft.Report.StiExportFormat.Pdf)
                // console.log("exportDocumentAsync")
                 //let buffer = Buffer.from(pdfData) // Converting Array into buffer        
                 // Saving string with rendered report in PDF into a file

                 //fs.writeFileSync('./SimpleList.pdf', buffer);
               
                // let blob = new Blob([new Uint8Array(pdfData)], { type: "application/pdf" });
                 let newPdfFile = new File([new Uint8Array(pdfData)], tituloPDF+'.pdf', { type: "application/pdf"  });    
                 setArchivo(newPdfFile)
               /*   if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    let fileName = (report.reportAlias == null || report.reportAlias.trim().length == 0) ? report.reportName : report.reportAlias;
                     window.navigator.msSaveOrOpenBlob(blob, fileName + ".pdf");
                 }
                 else {
                    let fileUrl = URL.createObjectURL(blob);
                     window.open(fileUrl);
                 }  console.log("Rendered report saved into PDF-file.");
                 console.log(blob);
                 console.log(newPdfFile); */
                // res.status(200).send({ respuesta: 1, mensaje: '' });
             }, Stimulsoft.Report.StiExportFormat.Pdf);
         });
 
        viewer.report = report;
        viewer.onEmailReport = function (args) {

            // args.settings -  send email form
            
            // args.settings.email  -  email adress
            
            // args.settings.subject  -  email subject
            
            // args.settings.message  -  email message
            
             console.log(`Formato[${args.format}].email[${args.settings.email}.subject[${args.settings.subject}].message[${args.settings.message}].fileName[${args.fileName}].data[${args.data}]`)
            
            // args.format  -  export format - PDF, HTML, HTML 5, Excel2007, Word2007, CSV
            
            // args.fileName - report file name (name of attachement)
            
            // args.data  -  byte array with exported report file
            
            }
        viewer.renderHtml("viewerContent");
        // eslint-disable-next-line react-hooks/exhaustive-deps	
    },[]);
    return(        
        <div id="viewerContent"  style={{width:"30rem", height:"25rem",}}>Cargando datos...</div>        
    )
}
export default Reporte
